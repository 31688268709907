/**
 * NOTE: When adding or editing these, please make corresponding changes in the Notion page:
 * https://www.notion.so/column/Sync-Event-Statuses-294fd9d2977f4143aa16560739ce047d
 */
export const SyncStatusData = {
    // 100 Category: Column errors
    // These should alert integrations engineers to address, and should be
    // reserved for cases that require such attention. Issues that publishers or
    // support can handle should have a 400 or 500 category status.
    unexpected_column: {
        value: 100,
        label: 'Unexpected Error',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'We encountered an unexpected error and did not sync this notice. No action is required by the publication at this time.  Column engineers have already been notified about this error and are actively working to resolve the issue before ad deadline. Please allow Column the opportunity to investigate before contacting support. When the issue has been resolved, you will see "Notice synced successfully" in the activity log.',
        key: 'unexpected_column'
    },
    incomplete_implementation: {
        value: 101,
        label: 'Incomplete Implementation',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'Syncing this notice requires additional configuration on our backend. No action is required by the publication at this time. Column has already been notified about this error and is actively working to resolve the issue before ad deadline. Please allow Column the opportunity to investigate before contacting support. When the issue has been resolved, you will see "Notice synced successfully" in the activity log.',
        key: 'incomplete_implementation'
    },
    server_unavailable_column: {
        value: 103,
        label: 'Column Server Unavailable',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'We could not connect to our shared FTP server, so this notice will not sync; Column engineers have been notified to investigate the issue.',
        key: 'server_unavailable_column'
    },
    invoice_required_column: {
        value: 104,
        label: 'Column Invoice Required',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'This notice failed to sync because an invoice was not created',
        key: 'invoice_required_column'
    },
    upfront_payment_required_column: {
        value: 105,
        label: 'Column Upfront Payment Required',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'This notice failed to sync because upfront payment is required',
        key: 'upfront_payment_required_column'
    },
    // 200 Category: Success
    // Likely self-explanatory and least likely to need additional values.
    success: {
        value: 200,
        label: 'Success',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'Notice synced successfully.',
        key: 'success'
    },
    /**
     * This success status should only ever be used by the SyncEventLogger.createSuccessEvent() method,
     * which conditionally applies this status if the SyncEventLogger.addVisibleWarning() method was
     * used to add a warning. Otherwise, the standard `success` status will apply.
     */
    success_with_warning: {
        value: 207,
        label: 'Success with Warning',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'Notice synced successfully. However, issue(s) may require attention:',
        key: 'success_with_warning'
    },
    // 300 Category: No-ops
    // Cases where we want to capture status but not show the publisher the defaultMessage. Adding an
    // inAppMessage when creating these events will show the event in app.
    awaiting_response: {
        value: 300,
        label: 'Awaiting Response',
        defaultLocation: 'retool_only',
        defaultMessage: 'This notice is waiting for the publisher system to send a response. It may or may not sync.',
        key: 'awaiting_response.'
    },
    debounced: {
        value: 301,
        label: 'Debounced',
        defaultLocation: 'retool_only',
        defaultMessage: 'Concurrent sync trigger detected, combining events.',
        key: 'debounced'
    },
    delayed: {
        value: 302,
        label: 'Delayed',
        defaultLocation: 'retool_only',
        defaultMessage: 'The publisher has a delay configured for automatic syncs at deadline.',
        key: 'delayed'
    },
    verified_response: {
        value: 303,
        label: 'Verified Response',
        defaultLocation: 'retool_only',
        defaultMessage: 'We recieved a timely response from the publisher system',
        key: 'verified_response'
    },
    cancelled_before_first_sync: {
        value: 304,
        label: 'Cancelled before first sync',
        defaultLocation: 'retool_only',
        defaultMessage: 'The notice was cancelled prior to its first sync with the publisher system; no sync for cancellation is required.',
        key: 'cancelled_before_first_sync'
    },
    invoiced_outside_column: {
        value: 305,
        label: 'Invoiced outside of Column',
        defaultLocation: 'retool_only',
        defaultMessage: 'The notice is invoiced outside of Column',
        key: 'invoiced_outside_column'
    },
    /** @deprecated */
    subscribe_to_response: {
        value: 306,
        label: 'Subscribe to response',
        defaultLocation: 'retool_only',
        defaultMessage: 'This notice is subscribing the publisher system to receive a response. It may or may not sync.',
        key: 'subscribe_to_response'
    },
    /** @deprecated */
    response_file_detected: {
        value: 307,
        label: 'Response file detected in subscription',
        defaultLocation: 'retool_only',
        defaultMessage: 'We have detected a response file for this notice and will parse it for errors or notice content. It may or may not sync.',
        key: 'response_file_detected'
    },
    sync_cancelled_before_complete: {
        value: 308,
        label: 'Sync cancelled before it was completed',
        defaultLocation: 'retool_only',
        defaultMessage: 'This sync was cancelled before it was completed. There may be another sync in progress, or you can try syncing the notice again.',
        key: 'sync_cancelled_before_complete'
    },
    paid_direct_to_publisher: {
        value: 309,
        label: 'Invoice marked as paid',
        defaultLocation: 'retool_only',
        defaultMessage: 'The invoice was marked as paid',
        key: 'paid_direct_to_publisher'
    },
    payment_only_sync: {
        value: 310,
        label: 'Payment Only Sync',
        defaultLocation: 'retool_only',
        defaultMessage: 'Payment data synced to publisher system',
        key: 'payment_only_sync'
    },
    response_received_and_disregarded: {
        value: 311,
        label: 'Response received and disregarded',
        defaultLocation: 'retool_only',
        defaultMessage: 'An unexpected publisher response was received and disregarded (was not parsed)',
        key: 'response_received_and_disregarded'
    },
    notice_cancelled_at_deadline: {
        value: 312,
        label: 'Notice cancelled at deadline',
        defaultLocation: 'retool_only',
        defaultMessage: 'This notice was cancelled at deadline.',
        key: 'notice_cancelled_at_deadline'
    },
    sent_supporting_files: {
        value: 313,
        label: 'Sent supporting files',
        defaultLocation: 'retool_only',
        defaultMessage: 'Sent supporting files to publisher system.',
        key: 'sent_supporting_files'
    },
    periods_end_sync_request: {
        value: 314,
        label: `Period's end sync requested`,
        defaultLocation: 'retool_only',
        defaultMessage: `Period's end sync requested`,
        key: 'periods_end_sync_request'
    },
    notice_already_cancelled: {
        value: 315,
        label: 'Notice already cancelled',
        defaultLocation: 'retool_only',
        defaultMessage: `This notice has already been cancelled and should not be synced.`,
        key: 'notice_already_cancelled'
    },
    // 400 Category: Client error
    // Event message should add context to help publishers address the issue
    // (e.g., what required data is missing and how to resolve it)
    missing_required_data: {
        value: 400,
        label: 'Missing Required Data',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'This notice will not sync; it is missing required data.',
        key: 'missing_required_data'
    },
    confirmation_required: {
        value: 401,
        label: 'Confirmation Required',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'This notice will not sync unless it is confirmed by the publisher.',
        key: 'confirmation_required'
    },
    upfront_payment_required: {
        value: 402,
        label: 'Upfront Payment Required',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'This notice requires upfront payment, so it will not sync until it is paid for.',
        key: 'upfront_payment_required'
    },
    invoice_required: {
        value: 403,
        label: 'Invoice Required',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'This notice will not sync unless it has an invoice. Please create an invoice in order to sync this notice.',
        key: 'invoice_required'
    },
    notice_not_found: {
        value: 404,
        label: 'Notice Does Not Exist',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'We cannot sync this notice, as it does not exist in our database.',
        key: 'notice_not_found'
    },
    cannot_sync_ad_after_deadline: {
        value: 405,
        label: 'Cannot Sync After Deadline',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'We cannot sync this notice after deadline, so it will not sync.',
        key: 'cannot_sync_ad_after_deadline'
    },
    cannot_sync_ad_after_publication: {
        value: 406,
        label: 'Cannot Sync After Publication',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'We cannot sync this notice after the first publication date, so it will not sync.',
        key: 'cannot_sync_ad_after_publication'
    },
    customer_is_blocked: {
        value: 407,
        label: 'Cannot Sync with Blocked Customer',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'The associated customer is blocked from placing notices, so this notice will not sync.',
        key: 'customer_is_blocked'
    },
    invalid_billing_status: {
        value: 408,
        label: 'Invalid billing status',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'The invoice for this notice has an invalid billing status, so this notice will not sync.',
        key: 'invalid_billing_status'
    },
    invoice_mismatch: {
        value: 409,
        label: 'Invoice Mismatch',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'The line items on the invoice do not match the notice, please void/refund the invoice and create a new one.',
        key: 'invoice_mismatch'
    },
    cannot_sync_ad_before_deadline: {
        value: 410,
        label: 'Cannot Sync Before Deadline',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: `We cannot sync this notice before deadline, so it will not sync.`,
        key: 'cannot_sync_ad_before_deadline'
    },
    did_not_sync_by_deadline: {
        value: 411,
        label: 'Did Not Sync by Deadline',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: `This notice did not sync by its deadline. It either needs manual action to sync over, or it needs to be rescheduled if it's too late to go to print`,
        key: 'did_not_sync_by_deadline'
    },
    invalid_sizing: {
        value: 412,
        label: 'Invalid Size',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: `This notice is not a valid size to sync to your publishing system. Please edit the notice to change it's size before syncing again.`,
        key: 'invalid_sizing'
    },
    invalid_address: {
        value: 413,
        label: 'Invalid Address',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: `The address on this notice is invalid. Please correct the address before syncing again.`,
        key: 'invalid_address'
    },
    notice_is_a_teapot: {
        value: 418,
        label: 'Notice is a Teapot',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'This notice cannot brew coffee; it is a teapot.',
        key: 'notice_is_a_teapot'
    },
    // 500 Category: Publisher system errors
    // Event message should add context to help publishers address the issue
    // (e.g., who to contact about the problem)
    unexpected_publisher_system: {
        value: 500,
        label: 'Unexpected Error with Publisher System',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'We encountered an unexpected error when trying to send this notice to the publisher system. Resyncing may resolve the issue; if it persists please reach out to your IT team for additional support.',
        key: 'unexpected_publisher_system'
    },
    ad_is_locked: {
        value: 501,
        label: 'Ad is Locked',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: `This ad is currently locked in your publishing system, so we cannot sync changes. This likely means someone has it open and once they close the ad, we'll be able to sync.`,
        key: 'ad_is_locked'
    },
    ad_is_final: {
        value: 502,
        label: 'Publisher System No Longer Allows Changes',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: `We can no longer make changes to this ad in your publishing system. If you must, please manually adjust the ad in your publishing system.`,
        key: 'ad_is_final'
    },
    server_unavailable_publisher_system: {
        value: 503,
        label: 'Server Unavailable',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'We could not connect to the publisher system server to sync this notice. Resyncing may resolve the issue; if it persists please reach out to your IT team for additional support.',
        key: 'server_unavailable_publisher_system'
    },
    no_response_from_publisher_system: {
        value: 504,
        label: 'No Response from Publisher System',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'We did not receive a timely response from the publisher system; this notice may not sync.',
        key: 'no_response_from_publisher_system'
    },
    unexpected_response_from_publisher_system: {
        value: 505,
        label: 'Unexpected Reponse from Publisher System',
        defaultLocation: 'in_app_and_retool',
        defaultMessage: 'We received an unexpected response from the publisher system that we could not link to a corresponding sync attempt. This likely did not affect any sync attempt for this notice, but if this happens often, please reach out to your publisher system to troubleshoot.',
        key: 'unexpected_response_from_publisher_system'
    }
};
