import { jsx as _jsx } from "react/jsx-runtime";
import { Product } from 'lib/enums';
import MadlibEditor from 'routes/madlib/components/MadlibEditor';
import MinimalMce from '../MinimalMce';
const contentPlaceholderByProduct = {
    [Product.Classified]: 'Ad content',
    [Product.Notice]: 'Notice content',
    [Product.Obituary]: 'Write about your loved one...'
};
function ContentEditor({ adData, onChange, disableEditing, product, madlibProps }) {
    const { madlibData, madlibTemplate, setValidationResults, setRenderedHtml, setMadlibData } = madlibProps;
    const showMadlibEditor = !!madlibTemplate;
    if (showMadlibEditor) {
        return (_jsx(MadlibEditor, { rawTemplate: madlibTemplate, setRenderedHtml: setRenderedHtml, onTemplateDataChange: setMadlibData, madlibData: madlibData, setValidationResults: setValidationResults, 
            /**
             * We remove the `madlibData` and `content` fields from the handlebar data because we refresh the editor anytime the handlebar data changes.
             * Leaving these fields defined would cause the editor to refresh any time the user typed in the editor.
             * */
            handlebarData: Object.assign(Object.assign({}, adData), { madlibData: undefined, content: undefined }), ignoreStyling: true }));
    }
    return (_jsx(MinimalMce, { toolbarItems: [
            ['undo', 'redo', 'formatselect', 'bold', 'italic', 'underline']
        ], onChange: content => onChange({ content }), inline: true, minHeight: 150, value: (adData === null || adData === void 0 ? void 0 : adData.content) || '', id: "ad-content-editor", disabled: disableEditing, placeholder: contentPlaceholderByProduct[product] }));
}
export default ContentEditor;
