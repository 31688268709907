var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { safeStringify } from '../utils/stringify';
import { exists } from '../types';
import { getModelFromRef } from '../model';
import { PublicationIssueModel } from '../model/objects/publicationIssueModel';
import { PublicationIssueStatus } from '../types/publicationIssue';
import { getQueryResultsWhereKeyInArray } from '../utils/firebase';
import { getErrorReporter } from '../utils/errors';
import { getDeadlineRecordsForPublicationDate, getLatestDeadlineTimestampForDeadlines } from '../utils/deadlines';
import { getDateStringForDateInTimezone } from '../utils/dates';
import { wrapError, wrapSuccess } from '../types/responses';
import { getRejected } from '../helpers';
import { productTypeUsesPublicationIssueSections } from '../publicationIssues/publicationIssueSections';
import { ColumnService } from './directory';
export const getPublicationIssueForPublisher = (ctx, orgRef, publicationDate) => __awaiter(void 0, void 0, void 0, function* () {
    const publicationIssues = yield ctx
        .publicationIssuesRef()
        .where('publisher', '==', orgRef)
        .where('publicationDate', '==', publicationDate)
        .get();
    if (publicationIssues.docs.length > 1) {
        getErrorReporter().logAndCaptureWarning('[getPublicationIssueForPublisher] - Multiple publication issues found for publisher and publication date', {
            publicationDate,
            orgId: orgRef.id
        });
    }
    if (publicationIssues.docs.length === 0) {
        return null;
    }
    return new PublicationIssueModel(ctx, {
        snap: publicationIssues.docs[0]
    });
});
export const getPublicationIssueForDeadline = (ctx, deadline) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperRef = deadline.ref.parent.parent;
    const newspaperSnap = yield (newspaperRef === null || newspaperRef === void 0 ? void 0 : newspaperRef.get());
    if (!exists(newspaperSnap)) {
        getErrorReporter().logAndCaptureWarning('Deadline has no parent newspaper; cannot get publication issue', {
            deadlinePath: deadline.ref.path
        });
        return null;
    }
    const publicationDate = getDateStringForDateInTimezone({
        date: deadline.data().publicationDate.toDate(),
        timezone: newspaperSnap.data().iana_timezone
    });
    return getPublicationIssueForPublisher(ctx, newspaperSnap.ref, publicationDate);
});
export const createPublicationIssue = (ctx, params) => __awaiter(void 0, void 0, void 0, function* () {
    const { publicationDate, publisherId } = params;
    getErrorReporter().logInfo('Creating publication issue', {
        publicationDate,
        publisherId
    });
    let newStatus = PublicationIssueStatus.PENDING;
    const publisherRef = ctx.organizationsRef().doc(publisherId);
    const deadlines = yield getDeadlineRecordsForPublicationDate(ctx, publicationDate, publisherRef);
    const latestDeadlineTimestamp = getLatestDeadlineTimestampForDeadlines(deadlines);
    const publicationIssueIsInPast = moment
        .utc(publicationDate, 'YYYY-MM-DD')
        .isBefore(moment.utc().startOf('day'));
    if (!latestDeadlineTimestamp) {
        newStatus = PublicationIssueStatus.DISABLED;
    }
    else if (publicationIssueIsInPast) {
        newStatus = PublicationIssueStatus.ARCHIVED;
    }
    const publicationIssueRef = yield ctx.publicationIssuesRef().add({
        publisher: publisherRef,
        status: newStatus,
        publicationDate,
        deadlineTimestamp: latestDeadlineTimestamp,
        createdAt: ctx.fieldValue().serverTimestamp(),
        modifiedAt: ctx.fieldValue().serverTimestamp(),
        statusChanges: []
    });
    const publicationIssue = yield getModelFromRef(PublicationIssueModel, ctx, publicationIssueRef);
    return publicationIssue;
});
export const getOrCreatePublicationIssueForPublisher = (ctx, orgRef, publicationDate) => __awaiter(void 0, void 0, void 0, function* () {
    const existingPublicationIssue = yield getPublicationIssueForPublisher(ctx, orgRef, publicationDate);
    if (existingPublicationIssue) {
        return existingPublicationIssue;
    }
    getErrorReporter().logInfo('Existing publication issue not found; returning a newly created one', {
        publicationId: orgRef.id,
        publicationDate
    });
    return yield createPublicationIssue(ctx, {
        publicationDate,
        publisherId: orgRef.id
    });
});
/**
 * Entry point to query the publication issue collection.
 */
export const queryPublicationIssues = (ctx, query) => __awaiter(void 0, void 0, void 0, function* () {
    const { publisherIds, deadlineTimestampFrom, deadlineTimestampTo, publicationDateFrom, publicationDateTo, statuses, sectionType } = query;
    const querySetToDeadlineTimestampRange = deadlineTimestampFrom || deadlineTimestampTo;
    const querySetToPublicationDateRange = publicationDateFrom || publicationDateTo;
    if (querySetToDeadlineTimestampRange && querySetToPublicationDateRange) {
        return wrapError(new Error('Unable to query by both deadline and publication timestamp.  Please provide one or the other.'));
    }
    const queryIsForMoreThanTenPublishers = !publisherIds.length || publisherIds.length > 10;
    const queryHasNoTimeRange = !querySetToDeadlineTimestampRange && !querySetToPublicationDateRange;
    const queryHasNoStatusLimitation = !statuses || !statuses.length;
    const queryHasNeitherStatusNorTimeRestrictions = queryHasNoTimeRange && queryHasNoStatusLimitation;
    if (queryHasNeitherStatusNorTimeRestrictions &&
        queryIsForMoreThanTenPublishers) {
        return wrapError(new Error('Unable to query publication issues for more than 10 publishers without a specified time range or status limitation. Please restrict the query accordingly.'));
    }
    let publicationIssuesQuery = ctx.publicationIssuesRef();
    if (deadlineTimestampFrom) {
        publicationIssuesQuery = publicationIssuesQuery.where('deadlineTimestamp', '>=', ctx.timestampFromDate(deadlineTimestampFrom));
    }
    if (deadlineTimestampTo) {
        publicationIssuesQuery = publicationIssuesQuery.where('deadlineTimestamp', '<=', ctx.timestampFromDate(deadlineTimestampTo));
    }
    if (publicationDateFrom) {
        publicationIssuesQuery = publicationIssuesQuery.where('publicationDate', '>=', publicationDateFrom);
    }
    if (publicationDateTo) {
        publicationIssuesQuery = publicationIssuesQuery.where('publicationDate', '<=', publicationDateTo);
    }
    // In case of querying sections, the status filter will be applied on sections not publication issues
    if (statuses && !productTypeUsesPublicationIssueSections(sectionType)) {
        publicationIssuesQuery = publicationIssuesQuery.where('status', 'in', statuses);
    }
    let publicationIssues = [];
    try {
        let publicationIssueResults;
        if (publisherIds.length) {
            const publisherRefs = publisherIds.map(id => {
                return ctx.organizationsRef().doc(id);
            });
            publicationIssueResults = yield getQueryResultsWhereKeyInArray(publicationIssuesQuery, 'publisher', publisherRefs);
        }
        else {
            publicationIssueResults = (yield publicationIssuesQuery.get()).docs;
        }
        publicationIssues = publicationIssueResults.map(doc => {
            return new PublicationIssueModel(ctx, { snap: doc });
        });
    }
    catch (error) {
        getErrorReporter().logAndCaptureError(ColumnService.GENERAL_INFRASTRUCTURE, error, 'Error querying publication issues', {
            query: safeStringify(query) || ''
        });
        return wrapError(error);
    }
    return wrapSuccess(publicationIssues);
});
// This function returns sections associated with the given publication issues that match the criteria in the given query
export const queryPublicationIssuesAndSections = (publicationIssues, query) => __awaiter(void 0, void 0, void 0, function* () {
    const { statuses, sectionType } = query;
    const publicationIssuesWithSections = [];
    if (sectionType && productTypeUsesPublicationIssueSections(sectionType)) {
        const result = yield Promise.allSettled(publicationIssues.map((publicationIssue) => __awaiter(void 0, void 0, void 0, function* () {
            // Get the section associated with the publication issue
            const { response: section, error: sectionError } = yield publicationIssue.maybeGetOrCreateSection(sectionType);
            if (sectionError) {
                throw sectionError;
            }
            else if (section === null) {
                publicationIssuesWithSections.push({
                    publicationIssue,
                    section: null
                });
            }
            else if (statuses === null || statuses === void 0 ? void 0 : statuses.length) {
                // Apply the status filter on sections
                if (statuses.includes(section.modelData.status)) {
                    publicationIssuesWithSections.push({
                        publicationIssue,
                        section
                    });
                }
            }
            else {
                publicationIssuesWithSections.push({
                    publicationIssue,
                    section
                });
            }
        })));
        const errors = getRejected(result);
        if (errors.length) {
            return wrapError(errors[0]);
        }
    }
    else {
        for (const publicationIssue of publicationIssues) {
            publicationIssuesWithSections.push({ publicationIssue, section: null });
        }
    }
    return wrapSuccess(publicationIssuesWithSections);
});
export const sortPublicationIssuesByDate = (publicationIssues, sortOrder) => {
    return publicationIssues.sort((a, b) => {
        const orderModifier = sortOrder === 'asc' ? 1 : -1;
        const comparison = a.publicationIssue.modelData.publicationDate.localeCompare(b.publicationIssue.modelData.publicationDate);
        return comparison * orderModifier;
    });
};
