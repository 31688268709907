var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { MANUAL_BUILD_AD_REQUEST, MANUAL_BUILD_AD_REQUEST_SYNC, MANUAL_CANCEL_BUILD_AD_REQUEST } from 'lib/types/events';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { Alert } from 'lib/components/Alert';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { isNoticeAfterPublicationDeadline } from 'lib/utils/deadlines';
import { safeStringify } from 'lib/utils/stringify';
import * as affinityXLibHelpers from 'lib/integrations/affinityx/helpers';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import AffinityXSyncButton from './AffinityXSyncButton';
import AffinityXSyncInputs from './AffinityXSyncInputs';
import AffinityXSyncHelpText from './AffinityXSyncHelpText';
import { AffinityXSyncStatus, getValidAffinityXOrderNumber, getAffinityXSyncStatus, getMaxAdHeightForTemplate } from './helpers';
import AffinityXSyncStatusPill from './AffinityXSyncStatusPill';
function AffinityXSyncPanel({ noticeSnap, newspaperSnap, requestingUser, push }) {
    const [numColumns, setNumColumns] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    const [approxHeight, setApproxHeight] = useState(null);
    const [heightIsValid, setHeightIsValid] = useState(true);
    const [maxHeight, setMaxHeight] = useState(null);
    const [syncStatus, setSyncStatus] = useState(null);
    const [orderNumber, setOrderNumber] = useState(null);
    const [noticeIsAfterDeadline, setNoticeIsAfterDeadline] = useState(true);
    const affinityBuildEventsQueryRef = affinityXLibHelpers.getLatestAffinityXEventsQuery(getFirebaseContext(), { notice: noticeSnap.ref, type: MANUAL_BUILD_AD_REQUEST });
    const affinityBuildEventsQuerySnap = useFirestoreQueryListener(affinityBuildEventsQueryRef, [noticeSnap.id]);
    // The query to get the trigger events calls a descending sort on `createdAt`
    const mostRecentTriggerEvent = affinityBuildEventsQuerySnap === null || affinityBuildEventsQuerySnap === void 0 ? void 0 : affinityBuildEventsQuerySnap.docs[0];
    const affinityCancelEventsQueryRef = affinityXLibHelpers.getLatestAffinityXEventsQuery(getFirebaseContext(), { notice: noticeSnap.ref, type: MANUAL_CANCEL_BUILD_AD_REQUEST });
    const affinityCancelEventsQuerySnap = useFirestoreQueryListener(affinityCancelEventsQueryRef, [noticeSnap.id]);
    const affinitySyncEventsQueryRef = affinityXLibHelpers.getLatestAffinityXEventsQuery(getFirebaseContext(), {
        notice: noticeSnap.ref,
        type: MANUAL_BUILD_AD_REQUEST_SYNC
    });
    const affinitySyncEventsQuerySnap = useFirestoreQueryListener(affinitySyncEventsQueryRef, [noticeSnap.id]);
    useEffect(() => {
        const determineWhetherAfterDeadline = () => __awaiter(this, void 0, void 0, function* () {
            const afterDeadline = yield isNoticeAfterPublicationDeadline(noticeSnap);
            setNoticeIsAfterDeadline(afterDeadline);
        });
        void determineWhetherAfterDeadline();
    }, [noticeSnap.id]);
    useEffect(() => {
        var _a;
        const { customId } = noticeSnap.data();
        if (!customId || !affinityXLibHelpers.isAffinityXOrderNumber(customId)) {
            return;
        }
        try {
            const orderNum = getValidAffinityXOrderNumber({
                customId,
                mostRecentTriggerEvent,
                cancelEvents: (_a = affinityCancelEventsQuerySnap === null || affinityCancelEventsQuerySnap === void 0 ? void 0 : affinityCancelEventsQuerySnap.docs) !== null && _a !== void 0 ? _a : [],
                /**
                 * Since creating a new order number in AffinityX
                 * may incur additional charges for the publisher,
                 * we don't want to do this automatically. Instead,
                 * we should keep incrementing the order number a
                 * manual action.
                 */
                incrementOrderNumberIfUsed: false
            });
            setOrderNumber(orderNum);
        }
        catch (err) {
            logAndCaptureException(ColumnService.AFFINITY_X, err, 'Error generating AffinityX order number', {
                noticeId: noticeSnap.id,
                customId
            });
        }
    }, [
        noticeSnap.data().customId,
        mostRecentTriggerEvent === null || mostRecentTriggerEvent === void 0 ? void 0 : mostRecentTriggerEvent.id,
        safeStringify(affinityCancelEventsQuerySnap === null || affinityCancelEventsQuerySnap === void 0 ? void 0 : affinityCancelEventsQuerySnap.docs)
    ]);
    useEffect(() => {
        const setInitialInputValues = () => __awaiter(this, void 0, void 0, function* () {
            const maxHeightForTemplate = yield getMaxAdHeightForTemplate(noticeSnap.data().adTemplate);
            setMaxHeight(maxHeightForTemplate);
            if (!mostRecentTriggerEvent) {
                setApproxHeight(maxHeightForTemplate);
                return;
            }
            const { numberOfColumns, approxHeightInInches, pageCount } = mostRecentTriggerEvent.data().data;
            setNumColumns(numberOfColumns);
            setApproxHeight(approxHeightInInches || maxHeightForTemplate);
            setPageCount(pageCount || 1);
        });
        void setInitialInputValues();
    }, [mostRecentTriggerEvent === null || mostRecentTriggerEvent === void 0 ? void 0 : mostRecentTriggerEvent.id, noticeSnap.data().adTemplate.id]);
    useEffect(() => {
        const fetchSyncStatus = () => __awaiter(this, void 0, void 0, function* () {
            if (!affinityBuildEventsQuerySnap) {
                return;
            }
            const updatedSyncStatus = yield getAffinityXSyncStatus({
                affinityBuildEventsQuerySnap,
                orderNumber
            });
            setSyncStatus(updatedSyncStatus);
        });
        void fetchSyncStatus();
        /* ideally, events should never be deleted or edited,
        so event additions should be the only change to the
        affinityBuildEventsQuerySnap and
        affinityCancelEventsQuerySnap dependency vars */
    }, [
        affinityBuildEventsQuerySnap === null || affinityBuildEventsQuerySnap === void 0 ? void 0 : affinityBuildEventsQuerySnap.size,
        affinityCancelEventsQuerySnap === null || affinityCancelEventsQuerySnap === void 0 ? void 0 : affinityCancelEventsQuerySnap.size,
        affinitySyncEventsQuerySnap === null || affinitySyncEventsQuerySnap === void 0 ? void 0 : affinitySyncEventsQuerySnap.size,
        orderNumber
    ]);
    const incrementOrderNumber = () => {
        var _a;
        const { customId } = noticeSnap.data();
        if (!customId || !affinityXLibHelpers.isAffinityXOrderNumber(customId)) {
            return;
        }
        try {
            const incrementedOrderNumber = getValidAffinityXOrderNumber({
                customId,
                mostRecentTriggerEvent,
                cancelEvents: (_a = affinityCancelEventsQuerySnap === null || affinityCancelEventsQuerySnap === void 0 ? void 0 : affinityCancelEventsQuerySnap.docs) !== null && _a !== void 0 ? _a : [],
                incrementOrderNumberIfUsed: true
            });
            setOrderNumber(incrementedOrderNumber);
        }
        catch (err) {
            logAndCaptureException(ColumnService.AFFINITY_X, err, 'Error incrementing AffinityX order number', {
                noticeId: noticeSnap.id,
                customId
            });
        }
    };
    return (_jsxs("div", Object.assign({ className: "mt-8" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-between" }, { children: [_jsx("p", Object.assign({ className: "text-xl font-bold text-column-gray-700" }, { children: "Sync to AffinityX" })), _jsx(AffinityXSyncStatusPill, { syncStatus: syncStatus })] })), syncStatus === AffinityXSyncStatus.SYNC_SUCCESSFUL && (_jsx("div", Object.assign({ className: "mt-2" }, { children: _jsx(Alert, { id: "affinity-x-success-edit-alert", icon: _jsx(ExclamationCircleIcon, { className: "h-5 w-5" }), description: _jsxs("p", { children: ["Notice is ready for review,", ' ', _jsx("span", Object.assign({ className: "font-bold underline cursor-pointer", onClick: () => {
                                    push && push(`/place/${noticeSnap.id}?step=confirm-ad`);
                                } }, { children: "click here" })), ' ', "or use the edit button to view it."] }) }) }))), _jsxs("div", Object.assign({ id: "affinity-x-sync-panel", className: "bg-primary-25 flex flex-col rounded-md border border-column-gray-150 p-4 mt-3 items-center" }, { children: [_jsx(AffinityXSyncInputs, { numColumns: numColumns, setNumColumns: setNumColumns, pageCount: pageCount, setPageCount: setPageCount, approxHeight: approxHeight, maxHeight: maxHeight, setApproxHeight: setApproxHeight, setHeightIsValid: setHeightIsValid, newspaperSnap: newspaperSnap, syncStatus: syncStatus }), _jsx(AffinityXSyncButton, { heightIsValid: heightIsValid, noticeSnap: noticeSnap, publicationSnap: newspaperSnap, syncStatus: syncStatus, noticeIsAfterDeadline: noticeIsAfterDeadline, setSyncStatus: setSyncStatus, orderNumber: orderNumber, reqData: {
                            numberOfColumns: numColumns || 0,
                            requestingUserId: requestingUser.id,
                            approxHeightInInches: approxHeight || 0,
                            pageCount: pageCount || 1
                        } }), _jsx(AffinityXSyncHelpText, { noticeSnap: noticeSnap, syncStatus: syncStatus, noticeIsAfterDeadline: noticeIsAfterDeadline, orderNumber: orderNumber, mostRecentTriggerEvent: mostRecentTriggerEvent, incrementOrderNumber: incrementOrderNumber })] }))] })));
}
export default AffinityXSyncPanel;
