import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TrashIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'components/CurrencyTextField/CurrencyTextField';
import { getRateAmountLabel } from '../rateUpdateFormUtils';
function RemoveRateButton({ onClick }) {
    return (_jsx("div", Object.assign({ className: "flex items-end ml-2" }, { children: _jsx(ColumnButton, { "aria-label": "Remove", destructive: true, secondary: true, onClick: onClick, endIcon: _jsx(TrashIcon, { className: "w-6 h-6" }), buttonText: "", size: "lg", type: "button" }) })));
}
export function RunBasedRateInput({ runNumber, rateType, value, onChange, deletable, adType }) {
    const getOrdinal = (num) => {
        switch (num) {
            case 1:
                return '1st';
            case 2:
                return '2nd';
            case 3:
                return '3rd';
            default:
                return `${num}th`;
        }
    };
    const rateAmountLabel = getRateAmountLabel(adType, rateType).toLowerCase();
    const labelText = `${getOrdinal(runNumber)} run ${rateAmountLabel}`;
    return (_jsx(GridInput, { children: _jsxs("div", Object.assign({ className: "flex" }, { children: [_jsx("div", Object.assign({ className: "flex-1" }, { children: _jsx(CurrencyTextField, { id: `rate-${runNumber}`, labelText: labelText, initialValue: value, onChange: onChange }) })), deletable && _jsx(RemoveRateButton, { onClick: deletable.onDelete })] })) }));
}
