import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { ReceiptPercentIcon } from '@heroicons/react/24/outline';
import { TextField } from 'lib/components/TextField';
import { Label } from 'lib/components/Label';
import moment from 'moment';
import RadioButtonGroup from 'lib/components/Checkbox/RadioButtonGroup';
import { GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'components/CurrencyTextField/CurrencyTextField';
import { timestampOrDateToTimestamp } from '../../utils/firebase';
import ColumnDatePicker from '../ColumnDatePicker';
export const FLAT_DISCOUNT_CONFIG = {
    type: 'fixedInCents',
    amount: 0,
    startTimestamp: timestampOrDateToTimestamp(new Date())
};
export const PERCENT_DISCOUNT_CONFIG = {
    type: 'percent',
    amount: 0,
    startTimestamp: timestampOrDateToTimestamp(new Date())
};
export function DiscountSettings({ value, onChange }) {
    var _a;
    const [error, setError] = useState('');
    return (_jsxs(_Fragment, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(RadioButtonGroup, { id: "discount-type", labelText: "What type of discount do you want to apply?", options: [
                        {
                            labelText: 'Flat Discount',
                            value: 'fixedInCents'
                        },
                        {
                            labelText: 'Percent Discount',
                            value: 'percent'
                        }
                    ], value: value.type, onChange: discountType => {
                        setError('');
                        switch (discountType) {
                            case 'fixedInCents': {
                                return onChange(FLAT_DISCOUNT_CONFIG);
                            }
                            case 'percent': {
                                return onChange(PERCENT_DISCOUNT_CONFIG);
                            }
                        }
                    } }) })), _jsx(GridInput, { children: value.type === 'fixedInCents' ? (_jsx(CurrencyTextField, { id: `discount-value}`, initialValue: value.amount, labelText: "How much is the discount?", onChange: newValue => {
                        onChange(Object.assign(Object.assign({}, value), { amount: newValue, startTimestamp: timestampOrDateToTimestamp(new Date()) }));
                    } })) : (_jsx(TextField, { id: 'discount-value-percent', labelText: 'How much is the discount?', errorText: error, type: "number", prefix: _jsx(ReceiptPercentIcon, { className: "w-5 h-5 text-column-gray-400" }), value: value.amount.toString() || '0', onChange: newValue => {
                        const percent = parseFloat(newValue);
                        if (!percent) {
                            onChange(Object.assign(Object.assign({}, value), { amount: 0 }));
                            setError('Percent discount must be a number');
                            return;
                        }
                        if (percent > 100 || percent < 0) {
                            onChange(Object.assign(Object.assign({}, value), { amount: percent }));
                            setError('Percent discount must be between 0 and 100');
                            return;
                        }
                        onChange(Object.assign(Object.assign({}, value), { amount: percent, startTimestamp: timestampOrDateToTimestamp(new Date()) }));
                        setError('');
                    } })) }), _jsxs(GridInput, { children: [_jsx(Label, Object.assign({ id: "date-filter-label" }, { children: "When do you want the discount to expire?" })), _jsx(ColumnDatePicker, { format: "MMM dd, yyyy", placeholderText: "Expiry Date", momentFormat: "MMM DD, YYYY", onChange: (possibleDate) => {
                            if (!possibleDate) {
                                return;
                            }
                            const date = timestampOrDateToTimestamp(moment(possibleDate).endOf('day').toDate());
                            onChange(Object.assign(Object.assign({}, value), { expirationTimestamp: date }));
                        }, value: ((_a = value === null || value === void 0 ? void 0 : value.expirationTimestamp) === null || _a === void 0 ? void 0 : _a.toDate()) || undefined })] })] }));
}
