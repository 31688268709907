var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import BigNumber from 'bignumber.js';
import { getFirebaseContext } from 'utils/firebase';
import { RateType } from 'lib/enums';
/**
 * Determines whether or not a rate snapshot supports display ads.
 * Right now only column inch, flat, and iowa form rates can support display ads.
 * @param rate rate to test
 * @returns {boolean}
 */
export const rateTypeSupportsDisplay = (rateType) => {
    if (rateType === RateType.column_inch.value) {
        return true;
    }
    if (rateType === RateType.flat.value) {
        return true;
    }
    if (rateType === RateType.iowa_form.value) {
        return true;
    }
    return false;
};
/**
 * Determines whether or not a rate is one of the default liner or display rates for an organization
 * @param organization organization to check
 * @param rate rate object
 * @returns {boolean}
 */
export const isDefaultRate = (organization, rateId) => {
    var _a, _b;
    return [
        (_a = organization.data().defaultLinerRate) === null || _a === void 0 ? void 0 : _a.id,
        (_b = organization.data().defaultDisplayRate) === null || _b === void 0 ? void 0 : _b.id
    ].includes(rateId);
};
export const DEFAULT_RATE_FILTER = {
    rateType: null,
    runType: null
};
/**
 * Formats inputs in a pretty fashion that may be numbers or strings. If the input is a number,
 * it will be formatted to the specified precision without trailing zeros.
 * @param value input to format
 * @param precision precision to format numeric values to
 * @returns {any} formatted value
 */
export const prettyFormatNumber = (value, precision = 4) => {
    var _a;
    if (typeof value === 'number') {
        // set the max precision of 4 decimals
        const precise = parseFloat(`${value}`).toFixed(precision);
        // strip trailing zeroes
        let withoutTrailing = precise.replace(/0+$/, '');
        // ensure we have a minimum of two decimals
        const numberOfDecimals = ((_a = withoutTrailing.split('.')[1]) === null || _a === void 0 ? void 0 : _a.length) || 0;
        for (let i = 0; i < Math.max(0, 2 - numberOfDecimals); i++) {
            withoutTrailing += '0';
        }
        return withoutTrailing;
    }
    return value;
};
/**
 * Converts cents to a string with 4 decimal places to support fractional cents.
 * Uses BigNumber to avoid floating point errors (ex. 120.32 / 100 = 1.2031999999999998)
 * Uses a custom BigNumber instance to avoid changing the global BigNumber instance.
 * @param cents
 * @returns {string} string with up to 4 decimal places
 */
export function centsToExtendedCurrency(cents) {
    const DECIMAL_PLACES = 4;
    const BNExtendedCurrency = BigNumber.clone({ DECIMAL_PLACES });
    let fourDecimalResult = new BNExtendedCurrency(cents)
        .dividedBy(100)
        .toFixed(DECIMAL_PLACES);
    // we support up to 4 decimal places, but we don't want to show trailing zeroes after the first two decimal places
    for (let i = 0; i < DECIMAL_PLACES - 2; i++) {
        if (fourDecimalResult.endsWith('0')) {
            fourDecimalResult = fourDecimalResult.slice(0, -1);
        }
    }
    return fourDecimalResult;
}
/**
 * Converts currency string to cents.
 * Uses BigNumber to avoid floating point errors (ex. 5.1 * 100 = 509.99999999999994)
 * @param currency
 * @returns {number} number of cents
 */
export function currencyToCents(currency) {
    return new BigNumber(currency).multipliedBy(100).toNumber();
}
/**
 * Combine the results of two queries into a single array, removing duplicates.
 */
const dedupeQueryCombination = (...queries) => {
    const seen = new Set();
    const deduped = [];
    for (const query of queries) {
        for (const doc of query.docs) {
            if (!seen.has(doc.id)) {
                deduped.push(doc);
                seen.add(doc.id);
            }
        }
    }
    return deduped;
};
/**
 * Determines all of the customers that are using a rate as either their liner or display rate.
 * @param organization organization we are searching for
 * @param rate rate object we are looking at
 * @returns {Promise<ESnapshotExists<ECustomer>[]>} set of customers using the rate
 */
export const getCustomersOnRate = (organization, rate) => __awaiter(void 0, void 0, void 0, function* () {
    const customersUsingRateAsLinerQuery = yield getFirebaseContext()
        .customersRef()
        .where('organization', '==', organization.ref)
        .where('linerRate', '==', rate.ref)
        .get();
    const customersUsingRateAsDisplayQuery = yield getFirebaseContext()
        .customersRef()
        .where('organization', '==', organization.ref)
        .where('displayRate', '==', rate.ref)
        .get();
    return dedupeQueryCombination(customersUsingRateAsLinerQuery, customersUsingRateAsDisplayQuery);
});
/**
 * Determines all of the customer organizations that are using a rate as either their liner or display rate.
 * @param organization organization we are looking at
 * @param rate rate we are looking at
 * @returns {Promise<ESnapshotExists<ECustomerOrganization>[]>} set of customer organizations using the rate
 */
export const getCustomerOrgsUsingRate = (organization, rate) => __awaiter(void 0, void 0, void 0, function* () {
    const customerOrgsUsingRateAsLinerQuery = yield getFirebaseContext()
        .customerOrganizationsRef()
        .where('organization', '==', organization.ref)
        .where('linerRate', '==', rate.ref)
        .get();
    const customerOrgsUsingRateAsDisplayQuery = yield getFirebaseContext()
        .customerOrganizationsRef()
        .where('organization', '==', organization.ref)
        .where('linerRate', '==', rate.ref)
        .get();
    return dedupeQueryCombination(customerOrgsUsingRateAsLinerQuery, customerOrgsUsingRateAsDisplayQuery);
});
/**
 * Determines whether or not we should show a row for a rate in the table
 * @param rate rate to test
 * @param searchString user input in a search field
 * @param filter filter state from our dialog
 * @returns {boolean} true if it should show up false otherwise
 */
export const shouldShowRateInTable = (rate, searchString, filter) => {
    var _a;
    if (!rate.data().description.toLowerCase().includes(searchString.toLowerCase())) {
        return false;
    }
    if (filter.rateType !== null &&
        ((_a = RateType.by_value(rate.data().rateType)) === null || _a === void 0 ? void 0 : _a.value) !== filter.rateType) {
        return false;
    }
    if (filter.runType === 'runBased' && !rate.data().runBased) {
        return false;
    }
    if (filter.runType === 'nonRunBased' && rate.data().runBased) {
        return false;
    }
    return true;
};
export const getNumActiveFromFilter = (filter) => {
    let totalActive = 0;
    if (filter.rateType !== null) {
        totalActive++;
    }
    if (filter.runType !== null) {
        totalActive++;
    }
    return totalActive;
};
/**
 * Determines whether or not a rate is a legacy rate. Legacy rates are defined
 * as rates in which:
 * 1. The rate charges the same amount per run
 * 2. The amount that rate charges per run depends on the total number of runs
 * @param rate
 * @returns
 */
export const isLegacyRate = (rate) => {
    const { runBased, rate_0, rate_1, rate_2 } = rate;
    if (runBased)
        return false;
    if (rate_0 === rate_1 && rate_1 === rate_2)
        return false;
    return true;
};
/**
 * Determines whether or not to show the third run rate particular rate in the UI
 * We show the third run rate if:
 * 1) The rate has additional rates beyond the third pricing run
 * 2) The rate is a run based rate and the second run rate is different from the first run rate
 * @param {AdRate} rate
 * @returns {boolean} true if the third run rate should be shown, false otherwise
 */
export const shouldShowThirdRunRate = (rate) => {
    if (rate.additionalRates) {
        return true;
    }
    return rate.rate_2 !== rate.rate_1;
};
