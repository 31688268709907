import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Alert } from 'lib/components/Alert';
import { ExclamationCircle } from 'icons';
import { useBulkPaymentV2EnabledOnOrganization } from 'components/hooks/useBulkPaymentV2EnabledOnOrganization';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { BillingTermType, enumToSelectInput } from 'lib/enums';
import { supportsDisplay } from 'lib/notice/rates';
import { REQUIRE_UPFRONT_PAYMENT_TYPES, REQUIRE_UPFRONT_PAYMENT_OPTIONS } from './CreateOrEditCustomerModalSettings';
import { DiscountSettings, FLAT_DISCOUNT_CONFIG } from './DiscountSettings';
export function EditCustomerOrganizationModalSettings({ value, onChange, activeOrganization, rates }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const activeOrganizationHasBulkPaymentEnabled = useBulkPaymentV2EnabledOnOrganization(activeOrganization);
    const enableThirtyDaysEomBilling = getBooleanFlag(LaunchDarklyFlags.ENABLE_THIRTY_DAYS_END_OF_MONTH_BILLING, false);
    const linerRateIsArchived = !!((_b = (_a = value.linerRateSnap) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.archived);
    const displayRateIsArchived = !!((_d = (_c = value.displayRateSnap) === null || _c === void 0 ? void 0 : _c.data()) === null || _d === void 0 ? void 0 : _d.archived);
    const linerRateOptions = rates === null || rates === void 0 ? void 0 : rates.filter(rate => {
        var _a;
        if (((_a = value.linerRateSnap) === null || _a === void 0 ? void 0 : _a.id) === rate.id)
            return true;
        return !rate.data().archived;
    }).map(rate => {
        var _a;
        return ({
            value: rate.id,
            label: (_a = rate.data()) === null || _a === void 0 ? void 0 : _a.description
        });
    });
    const displayRateOptions = rates === null || rates === void 0 ? void 0 : rates.filter(rate => {
        var _a, _b, _c;
        if (((_a = value.displayRateSnap) === null || _a === void 0 ? void 0 : _a.id) === rate.id)
            return true;
        const rateSupportsDisplay = supportsDisplay(rate.data());
        const isOrganizationDefaultDisplayRate = rate.id === ((_c = (_b = activeOrganization.data()) === null || _b === void 0 ? void 0 : _b.defaultDisplayRate) === null || _c === void 0 ? void 0 : _c.id);
        return (isOrganizationDefaultDisplayRate ||
            (rateSupportsDisplay && !rate.data().archived));
    }).map(rate => {
        var _a;
        return ({
            value: rate.id,
            label: (_a = rate.data()) === null || _a === void 0 ? void 0 : _a.description
        });
    });
    const heading = "Set up this organization's preferences.";
    const description = 'Configure how you want this organization to interact with your publication.';
    return (_jsxs(CardGridLayout, Object.assign({ header: { title: heading, description } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(Alert, { id: "customization-warning", status: "warning", icon: _jsx(ExclamationCircle, { className: "w-5 h-5" }), description: "Customer organization liner and display rates are set to your organization's default settings. You should only make changes if you want a different rate for this organization." }) })), _jsx(GridInput, { children: _jsx(ColumnSelect, { labelText: "Liner rate", id: "liner_rate", value: (_e = value.linerRateSnap) === null || _e === void 0 ? void 0 : _e.id, options: linerRateOptions || [], onChange: newValue => {
                        const selectedRate = rates === null || rates === void 0 ? void 0 : rates.find(r => r.id === newValue);
                        if (selectedRate) {
                            onChange(Object.assign(Object.assign({}, value), { linerRateSnap: selectedRate }));
                        }
                    }, errorText: linerRateIsArchived
                        ? 'The selected rate is archived, please select another rate'
                        : undefined }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { labelText: "Display rate", id: "display_rate", value: (_f = value.displayRateSnap) === null || _f === void 0 ? void 0 : _f.id, options: displayRateOptions || [], onChange: newValue => {
                        const selectedRate = rates === null || rates === void 0 ? void 0 : rates.find(r => r.id === newValue);
                        if (selectedRate) {
                            onChange(Object.assign(Object.assign({}, value), { displayRateSnap: selectedRate }));
                        }
                    }, errorText: displayRateIsArchived
                        ? 'The selected rate is archived, please select another rate'
                        : undefined }) }), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { labelText: "Do you require upfront payment for this customer?", id: "upfront_payment", placeholder: "Require upfront payment?", value: value.upfrontPaymentSetting.id, disabled: !!value.bulkPaymentEnabled_v2, onChange: newValue => {
                        if (newValue === REQUIRE_UPFRONT_PAYMENT_TYPES.DEFAULT.id) {
                            onChange(Object.assign(Object.assign({}, value), { upfrontPaymentSetting: REQUIRE_UPFRONT_PAYMENT_TYPES.DEFAULT }));
                        }
                        else if (newValue === REQUIRE_UPFRONT_PAYMENT_TYPES.ALWAYS_REQUIRE.id) {
                            onChange(Object.assign(Object.assign({}, value), { upfrontPaymentSetting: REQUIRE_UPFRONT_PAYMENT_TYPES.ALWAYS_REQUIRE }));
                        }
                        else {
                            onChange(Object.assign(Object.assign({}, value), { upfrontPaymentSetting: REQUIRE_UPFRONT_PAYMENT_TYPES.NEVER_REQUIRE }));
                        }
                    }, options: REQUIRE_UPFRONT_PAYMENT_OPTIONS.map(option => ({
                        value: option.id,
                        label: option.description
                    })) }) })), !!((_g = activeOrganization.data()) === null || _g === void 0 ? void 0 : _g.allowInvoiceOutsideColumn) && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Invoice outside Column?", description: "By default, invoices created for this organization will be billed outside of Column.", value: !!value.invoicedOutsideColumn, onChange: invoicedOutsideColumn => {
                        onChange(Object.assign(Object.assign({}, value), { invoicedOutsideColumn }));
                    } }) }))), activeOrganizationHasBulkPaymentEnabled && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Allow bulk invoicing?", description: "Toggle this on if you want to create one monthly invoice for\n                this organization.", value: !!value.bulkPaymentEnabled_v2, onChange: enabled => {
                        onChange(Object.assign(Object.assign({}, value), { bulkPaymentEnabled_v2: enabled }));
                    }, disabled: !value.bulkPaymentEnabled_v2 }) }))), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Allow affidavits before payment?", description: " Customers will be allowed to download affidavits before paying\n              invoices.", value: !!value.enableAffidavitsBeforePayment, onChange: enableAffidavitsBeforePayment => {
                        onChange(Object.assign(Object.assign({}, value), { enableAffidavitsBeforePayment }));
                    } }) })), _jsx(SwitchControlledCard, Object.assign({ labelProps: {
                    label: 'Add a discount to this account?',
                    description: 'You can specify a flat discount or a percentage discount along with an expiration date.',
                    value: !!((_h = value.discountConfig) === null || _h === void 0 ? void 0 : _h.subtotal),
                    onChange: enabled => {
                        if (!enabled) {
                            onChange(Object.assign(Object.assign({}, value), { discountConfig: {} }));
                        }
                        else {
                            onChange(Object.assign(Object.assign({}, value), { discountConfig: { subtotal: FLAT_DISCOUNT_CONFIG } }));
                        }
                    }
                }, header: "" }, { children: _jsx(DiscountSettings, { value: (_k = (_j = value.discountConfig) === null || _j === void 0 ? void 0 : _j.subtotal) !== null && _k !== void 0 ? _k : FLAT_DISCOUNT_CONFIG, onChange: (discount) => {
                        onChange(Object.assign(Object.assign({}, value), { discountConfig: { subtotal: discount } }));
                    } }) })), enableThirtyDaysEomBilling && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "billing-term-select", labelText: "Default invoice due date", value: (_l = value.billingTerm) === null || _l === void 0 ? void 0 : _l.toString(), options: enumToSelectInput(BillingTermType), onChange: stringValue => onChange(Object.assign(Object.assign({}, value), { billingTerm: Number(stringValue) })) }) })))] })));
}
