import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnSelect, enumKeyToSelectInput } from 'lib/components/ColumnSelect';
import { Day } from 'lib/enums';
import { GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'components/CurrencyTextField/CurrencyTextField';
export function DayRateElement({ value, onChange }) {
    var _a;
    return (_jsxs(_Fragment, { children: [_jsx(GridInput, { children: _jsx(ColumnSelect, { labelText: "Day of the week", value: value.day.toString(), onChange: val => {
                        onChange(Object.assign(Object.assign({}, value), { day: parseInt(val, 10) }));
                    }, options: [
                        enumKeyToSelectInput(Day.sunday),
                        enumKeyToSelectInput(Day.monday),
                        enumKeyToSelectInput(Day.tuesday),
                        enumKeyToSelectInput(Day.wednesday),
                        enumKeyToSelectInput(Day.thursday),
                        enumKeyToSelectInput(Day.friday),
                        enumKeyToSelectInput(Day.saturday)
                    ], id: "day-rate-day" }) }), _jsx(GridInput, { children: _jsx(CurrencyTextField, { labelText: `Rate for ${(_a = Day.by_value(value.day)) === null || _a === void 0 ? void 0 : _a.label}`, initialValue: value.rate, onChange: val => {
                        onChange(Object.assign(Object.assign({}, value), { rate: val }));
                    }, id: "day-rate-rate" }) })] }));
}
