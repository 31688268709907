/* eslint-disable no-use-before-define */
import { z } from 'zod';
import { Collections } from '../constants';
// Firebase Auth signInProvider types currently supported by column:
export const ANONYMOUS = 'anonymous';
export const EMAIL_AND_PASSWORD = 'password';
export const CUSTOM = 'custom';
export const getFirebaseContextRefs = (factory) => {
    const { getDocRef, getCollectionRef, getSubcollectionGroupRef } = factory;
    return {
        adTemplatesRef: () => getCollectionRef(Collections.adTemplates),
        affidavitTemplatesRef: () => getCollectionRef(Collections.affidavitTemplates),
        cachesRef: (parent) => getCollectionRef(`${parent.path}/${Collections.caches}`),
        cacheEntriesRef: (parent) => getCollectionRef(`${parent.path}/${Collections.cacheEntries}`),
        cardsRef: () => getCollectionRef(Collections.cards),
        cardInvoicesRef: () => getCollectionRef(Collections.cardInvoices),
        cardTransactionsRef: () => getCollectionRef(Collections.cardTransactions),
        ledgerRef: () => getCollectionRef(Collections.ledger),
        classifiedsRef: () => getCollectionRef(Collections.classifieds),
        customerOrganizationsRef: () => getCollectionRef(Collections.customerOrganizations),
        customersRef: () => getCollectionRef(Collections.customers),
        deadlinesCollectionGroupRef: () => getSubcollectionGroupRef(Collections.deadlines),
        displaySitesRef: () => getCollectionRef(Collections.displaySites),
        displaySiteUploadIDsRef: (parent) => getCollectionRef(`${parent.path}/${Collections.uploadIDs}`),
        eeditionsRef: () => getCollectionRef(Collections.eeditions),
        emailConfirmationsRef: () => getCollectionRef(Collections.emailConfirmations),
        eventsRef: () => getCollectionRef(Collections.events),
        filingTypesRef: () => getCollectionRef(Collections.filingTypes),
        ftpFilesRef: () => getCollectionRef(Collections.ftpFiles),
        invitesRef: () => getCollectionRef(Collections.invites),
        invoicesRef: () => getCollectionRef(Collections.invoices),
        invoiceItemsRef: () => getCollectionRef(Collections.invoiceItems),
        invoiceTransactionsRef: (parent) => getCollectionRef(`${parent.path}/${Collections.invoiceTransactions}`),
        invoiceTransactionsCollectionGroupRef: () => getSubcollectionGroupRef(Collections.invoiceTransactions),
        joinRequestsRef: () => getCollectionRef(Collections.joinRequests),
        migrationsRef: () => getCollectionRef(Collections.migrations),
        modularSizesRef: () => getCollectionRef(Collections.modularSizes),
        newspaperOrdersCollectionGroupRef: () => getSubcollectionGroupRef(Collections.newspaperOrders),
        notarizationsRef: () => getCollectionRef(Collections.notarizations),
        notesRef: () => getCollectionRef(Collections.notes),
        notificationsRef: () => getCollectionRef(Collections.notifications),
        obituariesRef: () => getCollectionRef(Collections.obituaries),
        ordersRef: () => getCollectionRef(Collections.orders),
        orderNewspaperOrdersRef: (parent) => getCollectionRef(`${parent.path}/${Collections.newspaperOrders}`),
        organizationsRef: () => getCollectionRef(Collections.organizations),
        organizationDeadlinesRef: (parent) => getCollectionRef(`${parent.path}/${Collections.deadlines}`),
        organizationProductPublishingSettingsRef: (parent) => getCollectionRef(`${parent.path}/${Collections.productPublishingSettings}`),
        payoutsRef: () => getCollectionRef(Collections.payouts),
        previewNoticesRef: () => getCollectionRef(Collections.previewNotices),
        productPublishingSettingsCollectionGroupRef: () => getSubcollectionGroupRef(Collections.productPublishingSettings),
        publicNoticesRef: () => getCollectionRef(Collections.publicNotices),
        publicationIssuesRef: () => getCollectionRef(Collections.publicationIssues),
        publicationIssueAttachmentsRef: (parent) => getCollectionRef(`${parent.path}/${Collections.publicationIssueAttachments}`),
        publicationIssueSectionsCollectionGroupRef: () => getSubcollectionGroupRef(Collections.publicationIssueSections),
        publicationIssueSectionsRef: (parent) => getCollectionRef(`${parent.path}/${Collections.publicationIssueSections}`),
        publishingSettingsRef: () => getCollectionRef(Collections.publishingSettings),
        adRatesRef: () => getCollectionRef(Collections.rates),
        ratesRef: () => getCollectionRef(Collections.rates),
        runsRef: () => getCollectionRef(Collections.runs),
        stripeEventsRef: () => getCollectionRef(Collections.stripeevents),
        subscriptionsRef: () => getCollectionRef(Collections.subscriptions),
        transfersRef: () => getCollectionRef(Collections.transfers),
        userNoticesRef: () => getCollectionRef(Collections.userNotices),
        userNoticeFilesRef: (parent) => getCollectionRef(`${parent.path}/${Collections.noticeFiles}`),
        userDraftsRef: () => getCollectionRef(Collections.userDrafts),
        usersRef: () => getCollectionRef(Collections.users),
        updateSettingRequestsRef: () => getCollectionRef(Collections.updateSettingRequests),
        doc: (docPath) => {
            return getDocRef(docPath);
        },
        adjudicationAreasRef() {
            return getCollectionRef(Collections.adjudicationAreas);
        },
        organizationProductSiteSettingsRef: (parent) => getCollectionRef(`${parent.path}/productSiteSettings`),
        productSiteSettingsCollectionGroupRef: () => getSubcollectionGroupRef(Collections.productSiteSettings)
        // CODEGEN: GET-FIREBASE-CONTEXT-REFS - DO NOT DELETE OR MOVE
    };
};
/**
 * A zod schema for the props of a Firebase timestamp
 * https://firebase.google.com/docs/reference/js/v8/firebase.firestore.Timestamp#properties_1
 * https://googleapis.dev/nodejs/firestore/latest/Timestamp.html
 */
export const FirebaseTimestampSchema = z.custom(data => {
    return (data &&
        typeof data === 'object' &&
        typeof data.seconds === 'number' &&
        typeof data.toDate === 'function' &&
        typeof data.toMillis === 'function');
}, { message: 'Invalid Firebase Timestamp' });
