export const THICK_SPACER = 'thick-spacer';
export const THICK_SPACER_URL = 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F9a86.d90a2-thick_header.jpg';
export const SUPER_SKINNY_SPACER = 'three-col-spacer';
export const SUPER_SKINNY_SPACER_URL = 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F1169.f2e48-3-col-spacer.jpg';
export const NOTICE_ELEMENT_PREFIX = 'NOTICE';
export const SKINNY_SPACER_PREFIX = 'skinny-spacer';
export const SKINNY_HEADER_URL = 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2Fd600.7a390-skinny_header.jpg';
const LEE_ESCALATION_PHOENIX = 'phoenix.legals@lee.net';
const LEE_ESCALATION_MACTIVE = 'mactive.legals@lee.net';
const LEE_SOUTHEAST_ESCALATION_MACTIVE = 'SoutheastMactiveEscalations@lee.net';
const JOY = 'jmonopoli@rsnva.com';
const PAGINATION = 'pagination@column.us';
const PAUL = 'paul@column.us';
const TERESA = 'teresa@column.us';
export const TRELLO_INTEGRATION = 'jasonpupek1+a1taqjamrwyvrcirb0xq@boards.trello.com';
export const PAGINATION_INTEGRATION_EMAILS = [PAGINATION, TRELLO_INTEGRATION];
export const LEE_FTP_CONFIG = {
    format: 'FTP',
    host: 'upload-files.column.us',
    user: 'lee-pagination',
    password: '539760f6e7',
    port: 22,
    secure: false,
    path: ''
};
export const TEST_UPLOAD_DESTINATION = {
    label: 'Column Testing',
    newspaperCode: 'COLX',
    liaisonContacts: [PAGINATION, PAUL, TERESA],
    organizationId: 'fbhbFfBd6xl3LlTY35lz',
    ftpConfig: LEE_FTP_CONFIG
};
export const LEE_UPLOAD_DESTINATIONS = [
    {
        label: 'Times Democrat',
        newspaperCode: 'ONGT',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX, JOY],
        organizationId: '1Pe8dRZD5sZpuzCymn3h',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Pantagraph',
        newspaperCode: 'BLMP',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX, JOY],
        organizationId: 'HPeL6NJPpc5TxId2IYIP',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Woodford',
        newspaperCode: 'BLMW',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX, JOY],
        organizationId: '1sbbcSPBYwD5X6EVE0OB',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Herald & Review',
        newspaperCode: 'DECH',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX, JOY],
        organizationId: 'keQkIB7vlHi00vhCncVY',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Journal Gazette and Times-Courier',
        newspaperCode: 'MATJ',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX, JOY],
        organizationId: 's3wpzM2RIHTNrmpzneTq',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Hartsville Messenger',
        newspaperCode: 'SCAM',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: '6RMAJ1T0AV0IBfreq1sO',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Marion Star',
        newspaperCode: 'SCAS',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'ZKVtUGN2hz7A5sWHVMKF',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Florence Morning News',
        newspaperCode: 'SCAF',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'aLIOF7qmvXFN0QF1HzYn',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Press of Atlantic City',
        newspaperCode: 'PACP',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'PF6Oj7CkmWn4Ubpi6hvx',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Dothan Eagle',
        newspaperCode: 'DOTE',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'dHWAgbHhr8y0UOVCskpV',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Enterprise Ledger',
        newspaperCode: 'DOTL',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'MCCLddMb5yUOw68nBFEJ',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Eufaula Tribune',
        newspaperCode: 'DOTT',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'QsVXFzxpul1G5QGRuUI9',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Opelika Auburn News',
        newspaperCode: 'OANO',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'dQjz1hqNNEgeV8evafZb',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Bristol Herald Courier',
        newspaperCode: 'BHCB',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'cfPsOFiAW5qnpjrkL754',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Wytheville Enterprise',
        newspaperCode: 'SWVW',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'AHrz05Q6m0CjzZlGrq7O',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Smyth County News & Messenger',
        newspaperCode: 'SWVS',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'XR0oaU1rK9hCE9OB99hG',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'The Floyd Press',
        newspaperCode: 'SWVF',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'dtTG0xt67M0deRbKQ3nE',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Richlands News Press',
        newspaperCode: 'SWVR',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'xzae015jchEKg3nyP7Mw',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Roanoke Times',
        newspaperCode: 'ROAR',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'LIVF7f0edsUqFtUlKeuF',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Franklin News-Post',
        newspaperCode: 'FNPF',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'xUnG1hcY3INQAJ2eZIZm',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Fredericksburg Free Lance-Star',
        newspaperCode: 'FLSF',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'xPHHBfytSGjVHjEEz7p0',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Culpeper Star Exponent',
        newspaperCode: 'CSEC',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'BdH9Ws1BxPgsmXD0GsI1',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Amherst New Era Progress',
        newspaperCode: 'LNAA',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'wuC74H1SSHWVErVz4yWW',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Danville Register & Bee',
        newspaperCode: 'DAND',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'YVyxZiWwliRvs34WmT4s',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Lynchburg News & Advance',
        newspaperCode: 'LNAN',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'KEziG5VXuJCarfPZKQLT',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Nelson County Times',
        newspaperCode: 'LNAC',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: '6zy06zRc71q3rAz7CRJS',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Martinsville Bulletin',
        newspaperCode: 'MVBM',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'GHTiQc6rLsefcbpNqfIx',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Charlottesville Daily Progress',
        newspaperCode: 'CDPP',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'yUU7SoHjCSXeoNMqBs9a',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Waynesboro News Virginian',
        newspaperCode: 'WNVN',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: '65TB5LcJfIbRPXNOl0ji',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Greene County Record',
        newspaperCode: 'CDPG',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'erQiMl2XdfaSSmycjz3j',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Madison County Eagle',
        newspaperCode: 'CDPM',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'zBGfiNKfFrjcS6dGG9qr',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Orange County Review',
        newspaperCode: 'CDPO',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'EXrpgxUxFONXJfYoh2i2',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Rural Virginian',
        newspaperCode: 'CDPR',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'z65hdtnUrmFiq4xeeLOJ',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Richmond Times Dispatch',
        newspaperCode: 'RTDR',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'oEPFGiI1EOee385RJP2i',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Mechanicsville Ashland Local',
        newspaperCode: 'RTDM',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'KzLpEnSMwS954WhZ7twA',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Powhatan Today',
        newspaperCode: 'RTDP',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'YuQZPBc0cnnFmOY6QmZs',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Goochland Gazette',
        newspaperCode: 'RTDG',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'LjPeSt8Wk5otrwVhLKno',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'The Daily Nonpareil',
        newspaperCode: 'CBND',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'LBBgBhxQXosOjefXTPys',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Bulletin-Review',
        newspaperCode: 'DBRB',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'pq1HgfhaPvmhpWpLpcHK',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Southwest Iowa Herald',
        newspaperCode: 'SVNI',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'TWWebdDPgoVwfUV1cNyu',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Sarpy County Times',
        newspaperCode: 'CBNS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'vRqeKWUhxYVgY3iKgjrg',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'North Platte Telegraph',
        newspaperCode: 'NPTN',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'L8gQVS33jkkxRxHFt3x0',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Scottsbluff Star-Herald',
        newspaperCode: 'SSHS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'jOhEP5fq94qqxGzuY4W9',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Gering Courier',
        newspaperCode: 'SSHG',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'CbL1jXL0CTssKO3ZDuAh',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Wahoo Newspaper',
        newspaperCode: 'WAHW',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'tHlmcVh95YdfWlOoi285',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Waverly News',
        newspaperCode: 'WAHN',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: '86aHe6SJKQrrPLBYXZQB',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Ashland Gazette',
        newspaperCode: 'WAHA',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'xBG3rA6VtmNB1lPwcCBo',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Hemingford Ledger',
        newspaperCode: 'SSHH',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: '4jLtjSIMcz5e2E8MyW6P',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Greensboro News and Record',
        newspaperCode: 'GNRN',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: '7uRcB05utORKAPy56YWk',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Rockingham Now',
        newspaperCode: 'GNRR',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'fNRc9Bnsmqmg2XK5Nbny',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Hickory Daily Recod',
        newspaperCode: 'HDRR',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'ZsYymTcPolQY2Uuo7AwU',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Concord Independent Tribune',
        newspaperCode: 'CITI',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'wqWtyMGlvw4CMEm0CYOV',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'McDowell News',
        newspaperCode: 'MMNN',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'm74QygA6Ce9i5Vyt0MFX',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Winston-Salem Journal',
        newspaperCode: 'WSJJ',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'iVbvd0Ot1FrnQC4qCjfg',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Mooresville Tribune',
        newspaperCode: 'MOTT',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'Omkr2aDgZrIjAWAK085q',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Morganton News Herald',
        newspaperCode: 'MNHH',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'vGlQaGMKPP0kVFEbP4Rl',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Statesville Record and Landmark',
        newspaperCode: 'STAL',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: '91Gbsyi6khezIkL2cslb',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Lincoln Journal Star',
        newspaperCode: 'LJSJ',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'uIDVIq1Y2BzR1agKjVlj',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Beatrice Daily Sun',
        newspaperCode: 'BDSD',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'mcG0FjYH3aMDDfugWW4x',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Fremont Tribune',
        newspaperCode: 'FRET',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'srfWOTaOIsyO3qmXdOlu',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Billings Gazette',
        newspaperCode: 'BILG',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'vjm8mlPKDi6WoXKIGuCW',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Independent Record',
        newspaperCode: 'HELI',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'K510x9DSfxM8nJcse4Tq',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Montana Standard',
        newspaperCode: 'BUTS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'WaP8xnz7uXpnQv9QICb7',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Missoulian',
        newspaperCode: 'MISM',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'xBgxeM9xLNMPLYBVmV5W',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Ravalli Republic',
        newspaperCode: 'MISR',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'oFjtfMJjmRQVsT0cdMPI',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Quad-City Times',
        newspaperCode: 'QCTT',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '8dk7WZYxbGKi7RJQsRr2',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Muscatine Journal',
        newspaperCode: 'QCTJ',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'HASpJO0zQpuSjfBv47sF',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Dispatch Argus',
        newspaperCode: 'MDAD',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'MAvAvxNAEgkjmzIgdgXQ',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Sioux City Journal',
        newspaperCode: 'SCJJ',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '6ecRmpZPmAJSjvTTNVlQ',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Courier',
        newspaperCode: 'WOOC',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'xzdFkPxk10lWXoYJHvjy',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Globe Gazette',
        newspaperCode: 'MSCG',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '4m4efBcllBpnlr12eUNB',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Michell County Press-News',
        newspaperCode: 'MSCM',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'qp49ScofwxaejUswFlS4',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Summit-Tribune',
        newspaperCode: 'MSCS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '8DC6dt4MzTwMosxPEBnH',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Gazette Times - Democrat Herald',
        newspaperCode: 'MVNG',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '4hf5AHVDlA9y2kFlWu2O',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Napa Valley Register',
        newspaperCode: 'NVRR',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'OKlgzKJ8FrgJavk2KBJi',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'St. Helena Star',
        newspaperCode: 'NVRS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'xSmu6vkzFnWMTbZ8Saxg',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Daily News',
        newspaperCode: 'LNGN',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '1sM404s4cRBdLDm0BCSK',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Bismarck Tribune',
        newspaperCode: 'BIST',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'hRu6GeiIpiV0OwxisI3X',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Mandan News',
        newspaperCode: 'BISM',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'ewrEJuZJjyzRjCrYVvVh',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Rapid City Journal',
        newspaperCode: 'RCJR',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'VIrGdYW77kS55jiHgJWL',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Chadron Record',
        newspaperCode: 'RCJC',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '4Zg29lpfR5yUzcWSrnCt',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Casper Star-Tribune',
        newspaperCode: 'CAST',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'S0QRjldIjmTzQDGFgQ0R',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Lacrosse Tribune',
        newspaperCode: 'LACT',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'svQvGBfP9hqdTjG4cvnn',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Winona Daily News',
        newspaperCode: 'LACW',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '3EasZTZgneguAcfJF4LP',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Chippewa Herald',
        newspaperCode: 'LACC',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'lPdbuGY8jbHbjPVXKUby',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Houston County News',
        newspaperCode: 'LACH',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'ZBoq2tV6IxFTpLvSlcxC',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'La Crosse Courier',
        newspaperCode: 'LACL',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'BbmJ8agp55ahugc0KBYL',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Vernon County Times',
        newspaperCode: 'LACV',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'jNpNUjvuPFK6Vo0tzfyP',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Tomah Journal',
        newspaperCode: 'LACT',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'GqE92wqIRMXPi6SU8bro',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Dunn County News',
        newspaperCode: 'LACN',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'qgeVqXp8wSBViLQ4gBYa',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'The Sentinel',
        newspaperCode: 'SENS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'hTNn6TmBeF2iXNQSXY2L',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Post Star',
        newspaperCode: 'GLNP',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '93gfuexmNIGuYDVONRdw',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'The Citizen',
        newspaperCode: 'AUBC',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'osFTGmhqBtTUp5A6hUhd',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Journal Times',
        newspaperCode: 'RACJ',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'wpdLsSR1mOlae6UHami7',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Kenosha News',
        newspaperCode: 'KENK',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '8disduMvFVxYPC35slmY',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Lake Geneva Reporter',
        newspaperCode: 'KENL',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'rRirxYxtib0WVcwpmrBy',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Wisconsin State Journal',
        newspaperCode: 'MADW',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'nNVqpElC8Jo43s2BvpNA',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Baraboo News Republic',
        newspaperCode: 'BARR',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'JCJQysimemSe24duqYjE',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Portage Daily Register',
        newspaperCode: 'PTGP',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '0OsuGXLJB1NBnssqxb35',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Beaver Dam Daily Citizen',
        newspaperCode: 'BDMB',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'ykTadnpqf9gJqNFW8OGG',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'The Capital Times',
        newspaperCode: 'MADC',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'Tvq7r5MdGwLS5s6JDS9W',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Juneau County Star Times',
        newspaperCode: 'PTGS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'voYQEaC4GlrAB54rOEg2',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Wisconsin Dells Event',
        newspaperCode: 'PTGD',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '5wEoxcKU8C38W22JE0AL',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Agri-View',
        newspaperCode: 'MADA',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '0Cg1bqiGxI9eG7BNidga',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Times News',
        newspaperCode: 'TWNT',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'iHUFzbVrEpmBVfJC4ZjU',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Elko Daily Free Press',
        newspaperCode: 'ELKE',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'LC7fRTgiCjI97S60mmy8',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Kearney Hub',
        newspaperCode: 'KHPK',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '2mQdZxZMqZjcNak6hDjK',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Lexington Clipper-Herald',
        newspaperCode: 'LCHL',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'z4EfOE1DXuiCnb2gHmHc',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Grand Island Independent',
        newspaperCode: 'GIII',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'lJNwGxRwF2OBdSuVa1T8',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'The Columbus Telegram',
        newspaperCode: 'COLT',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'RpvBCZC6McpHo3ZSRTlN',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'David City Banner Press',
        newspaperCode: 'COLD',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '4J495Gu0Ad7tIYgRvzBw',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'Schuyler Sun',
        newspaperCode: 'COLS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'aOK4QX7Xjvc0M9pfLgm2',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'York News-Times',
        newspaperCode: 'YNTY',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'X4TiRxfuVzPZPSXuZHDu',
        ftpConfig: LEE_FTP_CONFIG
    },
    {
        label: 'St. Louis Post Dispatch',
        newspaperCode: 'STLD',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'S2sP2B8XQe4Bj2q7YzMC',
        ftpConfig: LEE_FTP_CONFIG
    },
    TEST_UPLOAD_DESTINATION
];
const GRAND_JUNCTION_ESCALATION = 'production@gjsentinel.com';
const MANHATTAN_MERCURY_ESCALATION = 'camera@themercury.com';
const SHERIDAN_PRESS_ESCALATION = 'production@thesheridanpress.com';
export const SEATON_FTP_CONFIG = {
    format: 'FTP',
    host: 'upload-files.column.us',
    user: 'seaton-pagination',
    password: 'I5w%2RYVP51$bx6MADRo',
    port: 22,
    secure: false,
    path: ''
};
export const SEATON_UPLOAD_DESTINATIONS = [
    {
        label: 'Grand Junction Daily Sentinel',
        newspaperCode: 'GJMI',
        liaisonContacts: [PAGINATION, GRAND_JUNCTION_ESCALATION],
        organizationId: 'S8fqyy2TfAOkN7xjZEts',
        ftpConfig: SEATON_FTP_CONFIG
    },
    {
        label: 'Manhattan Mercury',
        newspaperCode: 'MER',
        liaisonContacts: [PAGINATION, MANHATTAN_MERCURY_ESCALATION],
        organizationId: 'xVWmRsNEh9zobzmevsrr',
        ftpConfig: SEATON_FTP_CONFIG
    },
    {
        label: 'Sheridan Press',
        newspaperCode: 'TSP',
        liaisonContacts: [PAGINATION, SHERIDAN_PRESS_ESCALATION],
        organizationId: 'bTWNHYwzq7OVqHEKF6zw',
        ftpConfig: SEATON_FTP_CONFIG
    },
    {
        label: 'The Times, Pottawatomie County',
        newspaperCode: 'TTP',
        liaisonContacts: [PAGINATION, MANHATTAN_MERCURY_ESCALATION],
        organizationId: 'PoQDBMienp8ftWsCBkcH',
        ftpConfig: SEATON_FTP_CONFIG
    }
];
// TODO: We should stop using hardcoded configuration once pagination is used by
// 3 or more publishers. Instead, we should use firestore to save configuration,
// and build a UI in Admin to manage it.
export const PAGINATION_UPLOAD_DESTINATIONS = [
    ...LEE_UPLOAD_DESTINATIONS,
    ...SEATON_UPLOAD_DESTINATIONS
];
