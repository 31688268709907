var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnButton } from 'lib/components/ColumnButton';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import React, { useState } from 'react';
import { selectActiveOrganizationModel } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { logAndCaptureException } from 'utils';
import { Tooltip } from 'lib/components/Tooltip';
import { ColumnService } from 'lib/services/directory';
import { ConfirmCancellationModal } from './ConfirmCancellationModal';
export function CancelOrderButton({ order, onCancelOrder, isAnonymousUser }) {
    var _a;
    const activeOrganization = useAppSelector(selectActiveOrganizationModel);
    const [showConfirmCancellationModal, setShowConfirmCancellationModal] = useState(false);
    const { value: canCancelData } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const { response: canCancelOrder, error: canCancelOrderError } = yield order.getUserCanCancelOrder({
                activeOrganization,
                isAnonymousUser
            });
            if (canCancelOrderError) {
                logAndCaptureException(ColumnService.OBITS, canCancelOrderError, 'Error determining whether user can cancel order', {
                    orderId: order.id,
                    activeOrganizationId: activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id,
                    service: ColumnService.OBITS
                });
                return {
                    canCancel: false
                };
            }
            return canCancelOrder;
        }),
        dependencies: [order.id, order.modelData.status, activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id],
        initialData: {
            canCancel: false,
            reason: undefined
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ helpText: (_a = canCancelData === null || canCancelData === void 0 ? void 0 : canCancelData.reason) !== null && _a !== void 0 ? _a : '', position: "bottom" }, { children: _jsx(ColumnButton, { id: "cancel-order-button", type: "button", onClick: () => setShowConfirmCancellationModal(true), buttonText: 'Cancel order', disabled: !(canCancelData === null || canCancelData === void 0 ? void 0 : canCancelData.canCancel) }) })), showConfirmCancellationModal && (_jsx(ConfirmCancellationModal, { onClose: () => setShowConfirmCancellationModal(false), onCancelOrder: onCancelOrder, order: order }))] }));
}
