var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import Drawer from 'lib/components/Drawer';
import { getFirebaseContext } from 'utils/firebase';
import NotesTab from 'components/CustomerDrawer/NotesTab/NotesTab';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import TabGroup from 'lib/components/Tabs';
import { PublicationIssueAttachmentStatus } from 'lib/types/publicationIssueAttachment';
import { Product } from 'lib/enums';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { chunk } from 'lodash';
import { NoticesTab } from './NoticesTab';
import PublicationIssueDrawerHeader from './PublicationIssueDrawerHeader';
import BlocksTab from './BlocksTab';
import { OrdersTab } from './OrdersTab';
const MAX_FIRESTORE_IN_QUERY_SIZE = 10;
const NOTES_TAB = {
    label: 'Notes',
    enabled: true,
    id: 'notes'
};
const NOTICES_TAB = {
    label: 'Notices',
    enabled: true,
    id: 'notices'
};
const OBITUARIES_TAB = {
    label: 'Obituaries',
    enabled: true,
    id: 'obituaries'
};
const CLASSIFIEDS_TAB = {
    label: 'Classifieds',
    enabled: true,
    id: 'classifieds'
};
const BLOCKS_TAB = {
    label: 'Blocks',
    enabled: true,
    id: 'blocks'
};
const getTabs = (issueAttachments, product) => {
    if (product === Product.Obituary) {
        return [OBITUARIES_TAB];
    }
    if (product === Product.Classified) {
        return [CLASSIFIEDS_TAB];
    }
    return [
        Object.assign(Object.assign({}, BLOCKS_TAB), { enabled: issueAttachments.length > 0 }),
        Object.assign({}, NOTICES_TAB),
        Object.assign({}, NOTES_TAB)
    ];
};
const getTabFromProduct = (product) => {
    switch (product) {
        case Product.Obituary:
            return OBITUARIES_TAB;
        case Product.Classified:
            return CLASSIFIEDS_TAB;
        default:
            return NOTICES_TAB;
    }
};
export default function PublicationIssueDrawer({ publicationIssue, closeDrawer, product }) {
    const [issueAttachments, setIssueAttachments] = useState();
    const [activeTab, setActiveTab] = useState(getTabFromProduct(product));
    const user = useAppSelector(selectUser);
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            const issueAttachments = yield publicationIssue.getAttachments();
            const activeIssueAttachments = issueAttachments.filter(attachment => [
                PublicationIssueAttachmentStatus.APPROVED,
                PublicationIssueAttachmentStatus.PENDING
            ].includes(attachment.modelData.status));
            setIssueAttachments(activeIssueAttachments);
            if (activeIssueAttachments.length > 0) {
                setActiveTab(BLOCKS_TAB);
            }
        }))();
    }, [publicationIssue === null || publicationIssue === void 0 ? void 0 : publicationIssue.id]);
    const { value: notes } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const relevantOrganizations = (user === null || user === void 0 ? void 0 : user.data().allowedOrganizations) || [];
            const relevantOrganizationChunks = chunk(relevantOrganizations, MAX_FIRESTORE_IN_QUERY_SIZE);
            const noteResults = yield Promise.all(relevantOrganizationChunks.map((chunk) => __awaiter(this, void 0, void 0, function* () {
                const chunkQuery = yield getFirebaseContext()
                    .notesRef()
                    .where('publicationIssue', '==', publicationIssue.ref)
                    .where('noteCreatorOrganization', 'in', chunk)
                    .get();
                return chunkQuery.docs;
            })));
            return noteResults.flat();
        }),
        dependencies: [publicationIssue.id, user === null || user === void 0 ? void 0 : user.id]
    });
    const publisher = useFirestoreSnapshot(publicationIssue.modelData.publisher);
    if (!publisher || !issueAttachments) {
        return _jsx(_Fragment, {});
    }
    const tabs = getTabs(issueAttachments, product);
    return (_jsxs(Drawer, Object.assign({ onClose: closeDrawer, className: "flex flex-col", header: _jsx(PublicationIssueDrawerHeader, { publicationIssue: publicationIssue, publisher: publisher }), open: true }, { children: [_jsx(TabGroup, { tabs: tabs, activeTab: activeTab, onClickTab: setActiveTab, id: "publication-issue-drawer-tabs" }), activeTab.id === BLOCKS_TAB.id && (_jsx(BlocksTab, { issueAttachments: issueAttachments })), activeTab.id === NOTICES_TAB.id && (_jsx(NoticesTab, { publicationIssue: publicationIssue })), (activeTab.id === OBITUARIES_TAB.id ||
                activeTab.id === CLASSIFIEDS_TAB.id) && (_jsx(OrdersTab, { publicationIssue: publicationIssue, product: product })), activeTab.id === NOTES_TAB.id && (_jsx(NotesTab, { noteTopic: {
                    publicationIssue: publicationIssue.ref,
                    noteType: 'publication-issue-note'
                }, notes: notes }))] })));
}
