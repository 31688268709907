var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { useAppDispatch } from 'redux/hooks';
import deepEqual from 'deep-equal';
// note that we need to do this import from the enum directly to avoid overriding
// the child property
import { NotificationType, OccupationType } from 'lib/enums';
import { affidavitsAreManagedByColumn } from 'lib/affidavits';
import { ColumnButton } from 'lib/components/ColumnButton';
import { exists } from 'lib/types';
import SettingsHeader from '../SettingsHeader';
import FormSwitch from '../publisher/FormSwitch';
import { updateUserNotificationsSettings } from '../settingsActions';
/**
 * Removes irrelevant notifications for publishers in the UI. For example, don't show publishers
 * that aren't using automated affidavits the automated affidavit notification
 * @param notifications
 * @param activeOrg
 * @param childOrgs
 */
export const filterNotifications = (notifications, activeOrg, user) => {
    var _a;
    const occupationKey = (_a = OccupationType.by_value(user.data().occupation)) === null || _a === void 0 ? void 0 : _a.key;
    const filteredNotifications = Object.assign({}, notifications);
    Object.keys(filteredNotifications).forEach(notif_key => {
        const relevantNotification = NotificationType.by_key(notif_key);
        if (!relevantNotification) {
            delete filteredNotifications[notif_key];
            return;
        }
        if (!relevantNotification.isConfigurable) {
            delete filteredNotifications[notif_key];
            return;
        }
        if (occupationKey &&
            !relevantNotification.recipient_occupation_type_keys.includes(occupationKey)) {
            delete filteredNotifications[notif_key];
        }
    });
    delete filteredNotifications.survey_email;
    // delete affidavit notification if affidavits are not managed by column
    if (activeOrg && !affidavitsAreManagedByColumn(activeOrg)) {
        delete filteredNotifications.automated_affidavit_e_edition_upload_success;
    }
    return filteredNotifications;
};
function NotificationSettingsRow({ notification_key, notification, setNotification }) {
    const matchingEnum = notification_key === 'organization_level'
        ? {
            key: 'organization_level',
            label: 'Organization Notices',
            description: 'Receive notifications for all notices in your organisation, based on notification preferences below'
        }
        : NotificationType.by_key(notification_key);
    if (!matchingEnum)
        return null;
    const { label, key, description } = matchingEnum;
    return (_jsxs("tr", { children: [_jsxs("td", Object.assign({ className: "pl-6 pr-2 py-4 whitespace-no-wrap text-sm leading-5" }, { children: [_jsx("div", Object.assign({ className: "text-base text-column-gray-400 font-medium" }, { children: label })), _jsx("div", Object.assign({ className: `text-base text-column-gray-400 font-normal 
            ${key === 'invoice_notifications' && 'mb-6'}
          ` }, { children: description }))] })), _jsx("td", Object.assign({ className: "pl-6 pr-2 py-4 whitespace-no-wrap text-sm leading-5" }, { children: _jsx(FormSwitch, { id: `${notification_key}_email`, checked: notification.email, onChange: () => {
                        setNotification(Object.assign(Object.assign({}, notification), { email: !notification.email }));
                    } }) })), _jsx("td", Object.assign({ className: "px-6 py-4 whitespace-no-wrap text-sm leading-5" }, { children: _jsx("div", Object.assign({ className: "flex justify-end" }, { children: _jsx(FormSwitch, { checked: notification.inApp, id: `${notification_key}_inApp`, onChange: () => {
                            setNotification(Object.assign(Object.assign({}, notification), { inApp: !notification.inApp }));
                        } }) })) }))] }));
}
export default function NotificationSettings({ activeOrganization, user }) {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState();
    const [organizationLevelNotifications, setOrganizationLevelNotifications] = useState();
    useEffect(() => {
        if (!exists(user))
            return;
        if (!deepEqual(user === null || user === void 0 ? void 0 : user.data().notifications, notifications)) {
            setNotifications(user.data().notifications);
        }
        if (user.data().occupation !== OccupationType.publishing.value &&
            user.data().organization)
            setOrganizationLevelNotifications(user.data().receiveOrganizationNotifications);
    }, [user]);
    const updateNotifications = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        yield dispatch(updateUserNotificationsSettings({
            user,
            newNotifications: notifications,
            organizationLevelNotifications
        }));
        setLoading(false);
    });
    if (!notifications)
        return null;
    return (_jsxs("div", Object.assign({ className: "bg-white sm:rounded-lg border border-column-gray-100 shadow" }, { children: [_jsx(SettingsHeader, Object.assign({ header: "Notification Settings", description: "Control how often you hear from us." }, { children: _jsx(ColumnButton, { id: "submit_notifications_settings", loading: loading, buttonText: "Save Changes", onClick: () => updateNotifications(), primary: true, type: "button" }) })), _jsxs("table", Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", Object.assign({ className: "bg-column-gray-25 text-left text-xs leading-4 font-medium text-column-gray-300 uppercase tracking-wider" }, { children: ' ' })), _jsx("th", Object.assign({ className: "pl-2 pr-6 py-3 bg-column-gray-25 text-left text-xs leading-4 font-medium text-column-gray-300 uppercase tracking-wider" }, { children: "email" })), _jsx("th", Object.assign({ className: "px-6 py-3 bg-column-gray-25 text-left text-xs leading-4 font-medium text-column-gray-300 uppercase tracking-wider" }, { children: _jsx("div", Object.assign({ className: "flex justify-end" }, { children: "in app" })) }))] }) }), _jsxs("tbody", Object.assign({ className: "divide-y divide-gray-200 rounded-b-lg" }, { children: [organizationLevelNotifications && (_jsx(NotificationSettingsRow, { notification_key: 'organization_level', notification: Object.assign({ isConfigurable: true }, organizationLevelNotifications), setNotification: newNotification => {
                                    setOrganizationLevelNotifications({
                                        inApp: !!newNotification.inApp,
                                        email: !!newNotification.email
                                    });
                                } })), Object.keys(filterNotifications(notifications, activeOrganization, user))
                                .filter(notification => {
                                return NotificationType.by_key(notification);
                            })
                                .sort((a, b) => {
                                var _a, _b, _c, _d;
                                const priorityA = (_b = (_a = NotificationType.by_key(a)) === null || _a === void 0 ? void 0 : _a.priority) !== null && _b !== void 0 ? _b : 0;
                                const priorityB = (_d = (_c = NotificationType.by_key(b)) === null || _c === void 0 ? void 0 : _c.priority) !== null && _d !== void 0 ? _d : 0;
                                return priorityB - priorityA;
                            })
                                // eslint-disable-next-line array-callback-return
                                .map(notification_key => {
                                if (notification_key !== 'new_bulk_invoice')
                                    return (_jsx(NotificationSettingsRow, { notification_key: notification_key, notification: notifications[notification_key], setNotification: newNotification => {
                                            const newNotifications = Object.assign(Object.assign({}, notifications), { [notification_key]: newNotification });
                                            const notificationEnum = NotificationType.by_key(notification_key);
                                            if (notificationEnum) {
                                                for (const { key } of notificationEnum.children()) {
                                                    newNotifications[key] = Object.assign(Object.assign({}, newNotifications[key]), { inApp: newNotification.inApp, email: newNotification.email });
                                                }
                                            }
                                            setNotifications(newNotifications);
                                        } }, notification_key));
                            })] }))] }))] })));
}
