import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { isFlatAdditionalFee, isPercentAdditionalFee } from 'lib/types/rates';
import { TextField } from 'lib/components/TextField';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { getResetAdditionalFeeData } from 'lib/pricing/additionalFees';
import { useAppSelector } from 'redux/hooks';
import { selectContextOrganizationName } from 'redux/auth';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'components/CurrencyTextField/CurrencyTextField';
import { getAdditionalFeeTypeSelectionOption } from '../../ratesUtils';
export default function AdditionalFeeCard({ onUpdateAdditionalFee, updatedAdditionalFee }) {
    const organizationName = useAppSelector(selectContextOrganizationName);
    return (_jsxs(CardGridLayout, Object.assign({ header: {
            title: 'Cross-Paper fee',
            description: `Configure additional fees that show up across all rates for ${organizationName}.`
        } }, { children: [_jsx(GridInput, { children: _jsx(TextField, { id: "fee-description", labelText: "What would you like to call this fee?", value: updatedAdditionalFee.description, onChange: newValue => onUpdateAdditionalFee(Object.assign(Object.assign({}, updatedAdditionalFee), { description: newValue })) }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "fee-type", labelText: "How should this fee be calculated?", options: [
                        getAdditionalFeeTypeSelectionOption('flat'),
                        getAdditionalFeeTypeSelectionOption('percent')
                    ], value: updatedAdditionalFee.type, onChange: newValue => {
                        const newFeeType = newValue;
                        onUpdateAdditionalFee(getResetAdditionalFeeData(updatedAdditionalFee, newFeeType));
                    } }) }), isFlatAdditionalFee(updatedAdditionalFee) && (_jsx(GridInput, { children: _jsx(CurrencyTextField, { id: "fee-amount", labelText: "Set fee amount", initialValue: updatedAdditionalFee.amount, onChange: newValue => onUpdateAdditionalFee(Object.assign(Object.assign({}, updatedAdditionalFee), { amount: newValue })) }) })), isPercentAdditionalFee(updatedAdditionalFee) && (_jsx(GridInput, { children: _jsx(TextField, { id: "fee-percentage", labelText: "Set fee percentage", type: "number", step: "0.5", value: `${updatedAdditionalFee.feePercentage}`, suffix: "%", onChange: newValue => onUpdateAdditionalFee(Object.assign(Object.assign({}, updatedAdditionalFee), { feePercentage: parseFloat(newValue) })) }) }))] })));
}
