/**
 * Checks if two strings are very similar, ignoring casing and non-word characters.
 */
export const fuzzyStringMatch = (a, b) => {
    const aSimplified = a
        .toLocaleLowerCase()
        .trim()
        .replace(/[^a-z0-9\s]/gi, '')
        .replace(/\s/g, '');
    const bSimplified = b
        .toLocaleLowerCase()
        .trim()
        .replace(/[^a-z0-9\s]/gi, '')
        .replace(/\s/g, '');
    return aSimplified === bSimplified;
};
/**
 * Checks if one string contains (or nearly contains another), ignoring casing and non-word characters.
 */
export const fuzzyStringContains = (a, b) => {
    const aSimplified = a
        .toLocaleLowerCase()
        .trim()
        .replace(/[^a-z0-9\s]/gi, '')
        .replace(/\s/g, '');
    const bSimplified = b
        .toLocaleLowerCase()
        .trim()
        .replace(/[^a-z0-9\s]/gi, '')
        .replace(/\s/g, '');
    return aSimplified.indexOf(bSimplified) >= 0;
};
export const capitalizeWord = (word) => {
    return word.charAt(0).toLocaleUpperCase() + word.substring(1);
};
export const formatPhoneNumber = (phone, { digitsOnly = false } = {}) => {
    if (!phone) {
        return '';
    }
    const digits = phone.replace(/\D/g, '');
    if (digits.length < 10) {
        return '';
    }
    if (digitsOnly) {
        return digits;
    }
    return digits.slice(0, 10).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};
