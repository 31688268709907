var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { BadRequestError } from '../../errors/ColumnErrors';
export class ProductSiteSettingModel extends SnapshotModel {
    get type() {
        return Collections.productSiteSettings;
    }
    get url() {
        if (this.modelData.disabled) {
            return null;
        }
        return `https://${this.modelData.subdomainName}.column.us`;
    }
    getNewspaperRef() {
        return this.ref.parent.parent;
    }
    validateObject(data) {
        if (!data.product) {
            throw new Error('Product is required');
        }
        if (!data.subdomainName) {
            throw new Error('Subdomain name is required');
        }
        if (!data.publishTrigger) {
            throw new Error('Publish trigger is required');
        }
        if (data.disabled === undefined) {
            throw new Error('Disabled is required');
        }
    }
    /**
     * This update function can only be called from the BE due to firestore rule
     * restrictions.  This is because we must validate the subdomain is available
     * before calling this update function.
     */
    update(params) {
        const _super = Object.create(null, {
            update: { get: () => super.update }
        });
        return __awaiter(this, void 0, void 0, function* () {
            if (params.product && params.product !== this.modelData.product) {
                throw new BadRequestError('Product cannot be changed');
            }
            yield _super.update.call(this, params);
        });
    }
}
