var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TableLayout } from 'lib/components/TableLayout';
import React, { useEffect, useState } from 'react';
import TabGroup from 'lib/components/Tabs';
import BulkDownloadModal from 'routes/notices/table/BulkDownloadModal';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectAvailableOrganizations, selectShowAllOrgsNotices, selectUser } from 'redux/auth';
import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import { PublicationIssueStatus } from 'lib/types/publicationIssue';
import { exists } from 'lib/types';
import { publisherReadyToUpload } from 'lib/publishers';
import { fuzzyStringContains } from 'lib/utils/strings';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { getFirebaseContext } from 'utils/firebase';
import { getModelFromSnapshot } from 'lib/model';
import { logError, logInfo } from 'utils/logger';
import { shouldShowDisabledColumnForUser } from 'lib/pagination/helpers';
import { isColumnUser } from 'lib/helpers';
import { Product } from 'lib/enums';
import { Alert } from 'lib/components/Alert';
import PaginationTableRow from './PaginationTableRow';
import PaginationTableFilterDialog from './PaginationTableFilterDialog';
import { DEFAULT_PAGINATION_ISSUE_FILTER, DISABLED_TAB, PAGINATION_TABS, PAGINATION_TABS_TO_STATUSES, PENDING_TAB, filterHasChanges, getDatesForIssueQueryFromTableFilter, numberOfChanges, getTableHeaderText, OBIT_PAGINATION_TABS, CLASSIFIED_PAGINATION_TABS } from './paginationTableUtils';
import PublicationIssueDrawer from './drawer/PublicationIssueDrawer';
import { useGetPublicationIssues } from './hooks/useGetPublicationIssues';
import { PaginationUpload } from './upload/PaginationUpload';
export const PaginationDocumentation = require('./README.md').default;
export const paginationTabsByProduct = {
    [Product.Notice]: PAGINATION_TABS,
    [Product.Obituary]: OBIT_PAGINATION_TABS,
    [Product.Classified]: CLASSIFIED_PAGINATION_TABS
};
export default function Pagination() {
    // Controls which tab of pagination issue data is shown
    const [paginationTableTab, setPaginationTableTab] = useState(PENDING_TAB);
    const product = getLocationParams().get('product') || Product.Notice;
    const user = useAppSelector(selectUser);
    const availableOrganizations = useAppSelector(selectAvailableOrganizations);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const showAllOrgsNotices = useAppSelector(selectShowAllOrgsNotices);
    const columns = [
        'Newspaper',
        'Deadline',
        'Publication Date',
        'Status',
        ...(user && isColumnUser(user) ? ['Assignee'] : []),
        'Actions'
    ];
    const activeOrganizationId = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id;
    const DEFAULT_PUBLICATION_ISSUE_QUERY = {
        publisherIds: [],
        statuses: [PublicationIssueStatus.PENDING],
        sectionType: product
    };
    const [publicationIssueQuery, setPublicationIssueQuery] = useState(DEFAULT_PUBLICATION_ISSUE_QUERY);
    // Used to force a refresh of the publication issues
    const [refreshTimestamp, setRefreshTimestamp] = useState(Date.now());
    // Current issue filter (by ad deadline or by publication date)
    const [issueFilter, setIssueFilter] = useState(DEFAULT_PAGINATION_ISSUE_FILTER);
    const [updatedIssueFilter, setUpdatedIssueFilter] = useState(issueFilter);
    const [drawerPublicationIssue, setDrawerPublicationIssue] = useState();
    const [showBulkDownloadModal, setShowBulkDownloadModal] = useState(false);
    const [showPaginationUploadModal, setShowPaginationUploadModal] = useState(false);
    const [userError, setUserError] = useState('');
    const { loading: getPublicationIssuesLoading, error: getPublicationIssuesError, publicationIssuesWithSections } = useGetPublicationIssues(publicationIssueQuery, refreshTimestamp, paginationTableTab);
    const displayError = userError || getPublicationIssuesError;
    const reloadPublicationIssues = () => {
        setRefreshTimestamp(Date.now());
    };
    const getSelectedPublisherIds = () => {
        const publisherIds = showAllOrgsNotices
            ? availableOrganizations.map(org => org.id)
            : activeOrganizationId
                ? [activeOrganizationId]
                : [];
        return publisherIds;
    };
    useEffect(() => {
        const updatePublicationIssueQuery = () => {
            setPublicationIssueQuery(Object.assign(Object.assign({}, publicationIssueQuery), { statuses: PAGINATION_TABS_TO_STATUSES[paginationTableTab.id] }));
        };
        void updatePublicationIssueQuery();
    }, [paginationTableTab]);
    useEffect(() => {
        const updatePublicationIssueQuery = () => {
            const updatedDatesForQuery = getDatesForIssueQueryFromTableFilter(issueFilter);
            setPublicationIssueQuery(Object.assign(Object.assign({}, publicationIssueQuery), updatedDatesForQuery));
        };
        void updatePublicationIssueQuery();
    }, [issueFilter]);
    // This useEffect alleviates a race condition caused by the org selector not fully loading before the pagination table
    useEffect(() => {
        const publisherIds = getSelectedPublisherIds();
        setPublicationIssueQuery(Object.assign(Object.assign({}, publicationIssueQuery), { publisherIds }));
    }, [activeOrganizationId, showAllOrgsNotices, availableOrganizations.length]);
    const publicationIssueId = getLocationParams().get('publicationIssueID');
    useEffect(() => {
        if (!publicationIssueId)
            return;
        void (() => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const publicationIssue = yield getFirebaseContext()
                .publicationIssuesRef()
                .doc(publicationIssueId)
                .get();
            if (!exists(publicationIssue)) {
                logInfo('Attempted to view non-existing publication issue', {
                    publicationIssueId
                });
                return;
            }
            const urlIssueModel = getModelFromSnapshot(PublicationIssueModel, getFirebaseContext(), publicationIssue);
            const response = product !== Product.Notice
                ? yield urlIssueModel.maybeGetOrCreateSection(product)
                : null;
            if (response === null || response === void 0 ? void 0 : response.error) {
                logError('Unable to get section for a publication issue', {
                    publicationIssueId
                });
                return;
            }
            const tabId = Object.keys(PAGINATION_TABS_TO_STATUSES).find(tabId => PAGINATION_TABS_TO_STATUSES[tabId].includes(urlIssueModel.modelData.status));
            const tab = PAGINATION_TABS.find(tab => tab.id === tabId);
            if (tab) {
                setPaginationTableTab(tab);
            }
            else {
                logError('Attempted to view publication issue with invalid status', {
                    publicationIssueId
                });
                setPaginationTableTab(PENDING_TAB);
            }
            setDrawerPublicationIssue({
                publicationIssue: urlIssueModel,
                section: (_a = response === null || response === void 0 ? void 0 : response.response) !== null && _a !== void 0 ? _a : null
            });
        }))();
    }, [publicationIssueId]);
    const paginationTabs = paginationTabsByProduct[product];
    const visiblePaginationTabs = shouldShowDisabledColumnForUser(user)
        ? [...paginationTabs, DISABLED_TAB]
        : paginationTabs;
    // Consolidate loading conditions here
    const loading = getPublicationIssuesLoading;
    return (_jsxs("div", Object.assign({ id: "pagination-table-view", className: "pb-10 py-4 px-8 m-4" }, { children: [_jsx("header", Object.assign({ className: "flex justify-between pb-6 items-center" }, { children: _jsx("div", { children: _jsx("h1", Object.assign({ className: "text-2xl font-medium leading-tight text-column-gray-900 mb-1" }, { children: "Pagination" })) }) })), _jsxs("main", Object.assign({ className: "bg-white sm:rounded-lg border border-gray-300 mb-24 shadow-column-2" }, { children: [displayError && (_jsx(Alert, { title: "Error", description: displayError, id: "pagination-table-error" })), _jsx(TabGroup, { onClickTab: setPaginationTableTab, activeTab: paginationTableTab, tabs: visiblePaginationTabs, id: "pagination-table-tabs" }), _jsx("div", Object.assign({ id: "pagination-table" }, { children: _jsx(TableLayout, { filterable: {
                                shouldShowTableItem: (item, search) => {
                                    const publisherSnap = availableOrganizations.find(org => org.id === item.publicationIssue.modelData.publisher.id);
                                    if (!exists(publisherSnap) ||
                                        (product === Product.Notice &&
                                            !publisherReadyToUpload(publisherSnap))) {
                                        return false;
                                    }
                                    // check for matching on the organization name
                                    if (fuzzyStringContains(publisherSnap.data().name, search)) {
                                        return true;
                                    }
                                    // check for matching on the issue date
                                    if (fuzzyStringContains(item.publicationIssue.modelData.publicationDate, search)) {
                                        return true;
                                    }
                                    return false;
                                },
                                additionalFilters: {
                                    applyFilterChanges: () => {
                                        setIssueFilter(updatedIssueFilter);
                                    },
                                    filterHasChanges: filterHasChanges(issueFilter, updatedIssueFilter),
                                    numFiltersActive: numberOfChanges(issueFilter),
                                    resetFilters: () => {
                                        setUpdatedIssueFilter(DEFAULT_PAGINATION_ISSUE_FILTER);
                                        setIssueFilter(DEFAULT_PAGINATION_ISSUE_FILTER);
                                    },
                                    renderDialog: () => (_jsx(PaginationTableFilterDialog, { updatedFilter: updatedIssueFilter, setUpdatedFilter: setUpdatedIssueFilter }))
                                }
                            }, loading: loading, renderRow: publicationIssue => (_jsx(PaginationTableRow, { publicationIssue: publicationIssue.publicationIssue, section: publicationIssue.section, product: product, setShowBulkDownloadModal: setShowBulkDownloadModal, setShowPaginationUploadModal: setShowPaginationUploadModal, onUserError: setUserError, reloadPublicationIssues: reloadPublicationIssues })), header: getTableHeaderText(paginationTableTab.id, product), columns: columns, data: publicationIssuesWithSections.map(publicationIssueWithSection => (Object.assign(Object.assign({}, publicationIssueWithSection), { __id: publicationIssueWithSection.publicationIssue.id }))), clickable: {
                                onClick: row => setDrawerPublicationIssue(row)
                            }, pagination: { pageSize: 15 } }) })), drawerPublicationIssue && (_jsx(PublicationIssueDrawer, { publicationIssue: drawerPublicationIssue.publicationIssue, closeDrawer: () => setDrawerPublicationIssue(undefined), product: product })), !!showBulkDownloadModal && (_jsx(BulkDownloadModal, { setOpen: value => {
                            if (value === false) {
                                setShowBulkDownloadModal(false);
                            }
                        }, publicationDate: showBulkDownloadModal.modelData.publicationDate, organization: availableOrganizations.find(org => org.id === showBulkDownloadModal.modelData.publisher.id) || null })), !!showPaginationUploadModal && (_jsx(PaginationUpload, { publicationIssue: showPaginationUploadModal, reloadPublicationIssues: reloadPublicationIssues, setShowPaginationUploadModal: setShowPaginationUploadModal, setPaginationTableTab: setPaginationTableTab }))] }))] })));
}
