var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import FullScreenModal from 'components/FullScreenModal';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { TextField } from 'lib/components/TextField';
import ToastActions from 'redux/toast';
import { getFirebaseContext } from 'utils/firebase';
import { logAndCaptureException } from 'utils';
import CurrencyTextField from 'components/CurrencyTextField/CurrencyTextField';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
export function ModularSizeForm({ modularSizeRef, onClose, modularSize }) {
    const [updatedModularSizeData, setUpdatedModularSizeData] = useState(modularSize);
    const dispatch = useAppDispatch();
    const headerText = modularSizeRef ? 'Edit Modular Size' : 'New Modular Size';
    const saveButtonText = modularSizeRef
        ? 'Save Modular Size'
        : 'Create Modular Size';
    const onSubmit = () => __awaiter(this, void 0, void 0, function* () {
        try {
            if (modularSizeRef) {
                yield modularSizeRef.update(updatedModularSizeData);
            }
            else {
                yield getFirebaseContext()
                    .modularSizesRef()
                    .add(updatedModularSizeData);
            }
            onClose();
        }
        catch (e) {
            const headerText = 'Error saving modular size';
            logAndCaptureException(ColumnService.INDESIGN, e, headerText);
            dispatch(ToastActions.toastError({
                headerText,
                bodyText: 'Please try refreshing your page and re-submitting.'
            }));
        }
    });
    return (_jsx(FullScreenModal, Object.assign({ submittable: {
            buttonText: saveButtonText,
            onSubmit,
            disabled: false
        }, headerText: headerText, onClose: onClose, id: "modular-size-form" }, { children: _jsxs(CardGridLayout, { children: [_jsx(GridInput, { children: _jsx(TextField, { id: "modular-size-name", labelText: "What would you like to call this size?", placeholder: "ex: Half Page Horizontal", required: true, value: updatedModularSizeData.name, onChange: value => setUpdatedModularSizeData(Object.assign(Object.assign({}, updatedModularSizeData), { name: value })) }) }), _jsx(GridInput, { children: _jsx(CurrencyTextField, { id: "modular-size-price", labelText: "What is the design fee for this size?", initialValue: updatedModularSizeData.designFeeInCents, onChange: value => {
                            let newModularSizeData = updatedModularSizeData;
                            if (value) {
                                newModularSizeData = Object.assign(Object.assign({}, newModularSizeData), { designFeeInCents: value });
                            }
                            else {
                                const { designFeeInCents } = newModularSizeData, modularSizeWithoutFee = __rest(newModularSizeData, ["designFeeInCents"]);
                                newModularSizeData = modularSizeWithoutFee;
                            }
                            setUpdatedModularSizeData(newModularSizeData);
                        }, step: 0.01 }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "modular-size-width", type: "number", labelText: "What is the width (in inches) of this size?", min: 0.01, required: true, value: updatedModularSizeData.width.toString(), onChange: value => setUpdatedModularSizeData(Object.assign(Object.assign({}, updatedModularSizeData), { width: Number(value) })), step: 0.01 }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "modular-size-height", type: "number", labelText: "What is the height (in inches) of this size?", min: 0.01, required: true, value: updatedModularSizeData.height.toString(), onChange: value => setUpdatedModularSizeData(Object.assign(Object.assign({}, updatedModularSizeData), { height: Number(value) })), step: 0.01 }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "modular-size-internal-id", labelText: "What is the internal ID for this size?", value: updatedModularSizeData.internalId || '', onChange: value => setUpdatedModularSizeData(Object.assign(Object.assign({}, updatedModularSizeData), { internalId: value })) }) })] }) })));
}
