var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getReceiptUrlFromInvoice } from 'lib/utils/receipts';
import { useState } from 'react';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { useAppDispatch } from 'redux/hooks';
import { push } from 'connected-react-router';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { CancelOrderButton } from './actions/CancelOrderButton';
import MoreActions from './actions/MoreActions';
import { sendCancelOrderRequest } from './actions/cancelOrder';
import { EditOrderButton } from './actions/EditOrderButton';
export function OrderActions({ order, editData, anonymousUserAuthInfo }) {
    const dispatch = useAppDispatch();
    const [invoiceIsUpdating, setInvoiceIsUpdating] = useState(false);
    const { isLoading: invoiceIsGetting, value: invoice } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const { response: invoice, error: invoiceError } = yield order.getInvoice();
            if (invoiceError || !invoice) {
                return;
            }
            return invoice;
        }),
        dependencies: [order.id]
    });
    const receiptUrl = invoice ? getReceiptUrlFromInvoice(invoice) : undefined;
    const invoiceIsLoading = invoiceIsGetting || invoiceIsUpdating;
    const handleSendToEditFlow = () => __awaiter(this, void 0, void 0, function* () {
        const { response, error: duplicateError } = yield order.generateNewVersionOfOrderItems();
        if (duplicateError) {
            return wrapError(duplicateError);
        }
        const { version: newVersion } = response;
        const reauthorizationParams = anonymousUserAuthInfo
            ? `&accessCode=${anonymousUserAuthInfo.accessCode}&email=${encodeURIComponent(anonymousUserAuthInfo.authEmail)}`
            : '';
        dispatch(push(`/${PRODUCT_TO_NAME[order.modelData.product].plural.toLowerCase()}/edit/${order.id}?version=${newVersion}${reauthorizationParams}`));
        return wrapSuccess(undefined);
    });
    const handleCancelOrder = (cancellationReason) => __awaiter(this, void 0, void 0, function* () {
        setInvoiceIsUpdating(true);
        const { error: cancelError } = yield sendCancelOrderRequest(cancellationReason, order, anonymousUserAuthInfo);
        yield (invoice === null || invoice === void 0 ? void 0 : invoice.refreshData());
        setInvoiceIsUpdating(false);
        if (cancelError) {
            return wrapError(new Error('Failed to cancel order'));
        }
        return wrapSuccess(undefined);
    });
    return (_jsxs(_Fragment, { children: [_jsx(EditOrderButton, { editData: editData, onEditOrder: handleSendToEditFlow }), _jsx(CancelOrderButton, { order: order, onCancelOrder: handleCancelOrder, isAnonymousUser: !!anonymousUserAuthInfo }), _jsx(MoreActions, { order: order, receiptUrl: receiptUrl, invoiceIsLoading: invoiceIsLoading })] }));
}
