var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment-timezone';
import React from 'react';
import { selectAvailableOrganizations, selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { PublicationIssueStatus } from 'lib/types/publicationIssue';
import { isColumnUser } from 'lib/helpers';
import { Product } from 'lib/enums';
import { DownloadPaginationFilesButton } from './DownloadPaginationFilesButton';
import { UploadPaginationFilesButton } from './UploadPaginationFilesButton';
import { DeadlineBadge } from './DeadlineBadge';
import { IssueStatusBadge } from './IssueStatusBadge';
import { BuildPaginationFilesButton } from './BuildPaginationFilesButton';
import PaginatorBadge from './PaginatorBadge';
import { handleOrderPaginationDownload } from './handleOrderPaginationDownload';
export default function PaginationTableRow({ publicationIssue, section, product, setShowBulkDownloadModal, setShowPaginationUploadModal, onUserError, reloadPublicationIssues }) {
    var _a;
    const availableOrganizations = useAppSelector(selectAvailableOrganizations);
    const user = useAppSelector(selectUser);
    const publisherSnap = availableOrganizations.find(org => org.id === publicationIssue.modelData.publisher.id);
    const publisherName = (publisherSnap === null || publisherSnap === void 0 ? void 0 : publisherSnap.data().name) || '';
    const publisherTimezone = ((_a = publisherSnap === null || publisherSnap === void 0 ? void 0 : publisherSnap.data()) === null || _a === void 0 ? void 0 : _a.iana_timezone) || 'America/Chicago';
    if (!publisherSnap)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx("td", { children: publisherName }), _jsx("td", { children: _jsx(DeadlineBadge, { deadline: (section || publicationIssue).modelData.deadlineTimestamp, timezone: publisherTimezone }) }), _jsx("td", { children: moment(publicationIssue.modelData.publicationDate)
                    .format('MM/DD/YY')
                    .toString() }), _jsx("td", { children: _jsx(IssueStatusBadge, { publicationIssue: publicationIssue, section: section, reloadPublicationIssues: reloadPublicationIssues, publisher: publisherSnap, product: product }) }), user && isColumnUser(user) && (_jsx("td", { children: _jsx(PaginatorBadge, { publicationIssue: publicationIssue, section: section }) })), _jsx("td", { children: _jsxs("div", Object.assign({ className: "flex flex-row gap-2" }, { children: [_jsx(DownloadPaginationFilesButton, { setShowBulkDownloadModal: () => __awaiter(this, void 0, void 0, function* () {
                                if (product !== Product.Notice && section) {
                                    const result = yield handleOrderPaginationDownload({
                                        publicationIssueId: publicationIssue.id,
                                        product
                                    });
                                    if (result.response) {
                                        window.open(result.response);
                                    }
                                    else {
                                        onUserError('No orders found for download');
                                    }
                                }
                                else {
                                    setShowBulkDownloadModal(publicationIssue);
                                }
                            }), id: "download-pagination-files" }), product === Product.Notice && (_jsxs(_Fragment, { children: [_jsx(BuildPaginationFilesButton, { issue: publicationIssue }), _jsx(UploadPaginationFilesButton, { setShowPaginationUploadModal: () => setShowPaginationUploadModal(publicationIssue), disabled: publicationIssue.modelData.status ===
                                        PublicationIssueStatus.ARCHIVED, id: "upload-pagination-files" })] }))] })) })] }));
}
