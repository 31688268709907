var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { push } from 'connected-react-router';
import TemporaryPasswordResetContaner from 'layouts/signInLayout/TemporaryPasswordResetContainer';
import { logAndCaptureException } from 'utils';
import { loginUser } from 'utils/users';
import { getRedirect } from 'utils/urls';
import ToastActions from 'redux/toast';
import AuthActions from 'redux/auth';
import { apiPost } from 'api/typed';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { ColumnService } from 'lib/services/directory';
import { chameleonClient } from 'utils/chameleon';
import LoginUserForm, { DEFAULT_LOGIN_DATA } from './LoginUserForm';
import LoginUserFooter from './LoginUserFooter';
import LoginUserHeader from './LoginUserHeader';
import SocialLoginsForm from './SocialLoginsForm';
export default function Login() {
    const dispatch = useAppDispatch();
    const [loginData, setLoginData] = useState(DEFAULT_LOGIN_DATA);
    const [showTemporaryPasswordAlert, setShowTemporaryPasswordAlert] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    const isAdvertiserOnboardingSelfServeEnabled = useBooleanFlag(LaunchDarklyFlags.ADVERTISER_ONBOARDING_SELFSERVE);
    const login = () => __awaiter(this, void 0, void 0, function* () {
        setLoggingIn(true);
        dispatch(AuthActions.logout());
        const [success, authError] = yield loginUser(loginData.email, loginData.password);
        if (success) {
            dispatch(push(getRedirect()));
            chameleonClient.identify(loginData.email);
        }
        else {
            if (authError.code === 'auth/wrong-password') {
                try {
                    const res = yield apiPost('users/get-user-by-email', {
                        email: loginData.email
                    });
                    if (res.success && res.data.hasTemporaryPassword) {
                        setShowTemporaryPasswordAlert(true);
                        setLoggingIn(false);
                        return;
                    }
                }
                catch (err) {
                    logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Error getting user by email', {
                        email: loginData.email
                    });
                }
            }
            dispatch(ToastActions.toastError({
                headerText: 'Error logging in',
                bodyText: authError.message
            }));
        }
        setLoggingIn(false);
    });
    return (_jsxs("div", Object.assign({ className: "w-full" }, { children: [_jsx(LoginUserHeader, {}), _jsx(LoginUserForm, { onChangeLoginData: setLoginData, loginData: loginData, loggingIn: loggingIn, onSubmit: login }), isAdvertiserOnboardingSelfServeEnabled && _jsx(SocialLoginsForm, {}), showTemporaryPasswordAlert && (_jsx(TemporaryPasswordResetContaner, { email: loginData.email })), _jsx(LoginUserFooter, {})] })));
}
