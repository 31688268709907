import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField } from 'lib/components/TextField';
import { isFlatAdditionalFee } from 'lib/types/rates';
import { logAndCaptureMessage } from 'utils';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'components/CurrencyTextField/CurrencyTextField';
import { getRateUnit } from '../rateUpdateFormUtils';
import { centsToExtendedCurrency } from '../../ratesTableSettingsUtils';
const DEFAULT_OFFSET_FEE = {
    type: 'flat',
    amount: 0,
    description: 'Flat fee',
    perRun: true,
    isOffsetFee: true
};
const divideFees = (fees) => {
    if (!fees) {
        return { offsetFee: undefined, otherFees: undefined };
    }
    const offsetFees = fees
        .filter(isFlatAdditionalFee)
        .filter(fee => fee.isOffsetFee);
    const otherFees = fees.filter(fee => !isFlatAdditionalFee(fee) || !fee.isOffsetFee);
    if (offsetFees.length > 1) {
        logAndCaptureMessage('Found multiple offset fees on a single rate');
    }
    return { offsetFee: offsetFees[0], otherFees };
};
export function OffsetWithFlatFee({ value, initialValue, onChange }) {
    var _a;
    const offsetUnit = getRateUnit(value.product, value.rateType);
    const { offsetFee: initialFee } = divideFees(initialValue.additionalFees);
    const { offsetFee = DEFAULT_OFFSET_FEE, otherFees } = divideFees(value.additionalFees);
    return (_jsxs(SwitchControlledCard, Object.assign({ labelProps: {
            label: `Charge a flat fee for the first few ${offsetUnit === null || offsetUnit === void 0 ? void 0 : offsetUnit.plural}?`,
            description: `If enabled, you can configure a "flat fee with open rate" structure. Example: Charge $10 for the first 2 ${offsetUnit === null || offsetUnit === void 0 ? void 0 : offsetUnit.plural}, then $1 per ${offsetUnit === null || offsetUnit === void 0 ? void 0 : offsetUnit.singular} after that.`,
            value: typeof value.offset === 'number',
            onChange: shouldOffset => {
                var _a;
                return onChange({
                    offset: shouldOffset ? (_a = initialValue.offset) !== null && _a !== void 0 ? _a : 0 : null,
                    additionalFees: shouldOffset
                        ? [initialFee !== null && initialFee !== void 0 ? initialFee : DEFAULT_OFFSET_FEE, ...(otherFees !== null && otherFees !== void 0 ? otherFees : [])]
                        : otherFees
                });
            }
        }, header: "Rate Offsets" }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(CurrencyTextField, { initialValue: offsetFee === null || offsetFee === void 0 ? void 0 : offsetFee.amount, onChange: amount => onChange(Object.assign(Object.assign({}, value), { additionalFees: [Object.assign(Object.assign({}, offsetFee), { amount }), ...(otherFees !== null && otherFees !== void 0 ? otherFees : [])] })), id: "rate-offset-fee", labelText: "Flat fee amount" }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { labelText: `How many ${offsetUnit === null || offsetUnit === void 0 ? void 0 : offsetUnit.plural} are charged the flat fee?`, noteText: `After this initial amount, every additional ${offsetUnit === null || offsetUnit === void 0 ? void 0 : offsetUnit.singular} will cost $${value.rate_0 ? centsToExtendedCurrency(value.rate_0) : 0}`, id: "rate-offset", value: `${(_a = value.offset) !== null && _a !== void 0 ? _a : 0}`, onChange: stringOffset => {
                        onChange(Object.assign(Object.assign({}, value), { offset: parseFloat(stringOffset) }));
                    }, type: "number", max: 1000, min: 0 }) }))] })));
}
