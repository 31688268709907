var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState } from 'react';
import { useLoading } from 'lib/components/hooks/useLoading';
import { Tooltip } from 'lib/components/Tooltip';
import { noticeIsPublished } from 'lib/helpers';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { logAndCaptureException } from 'utils';
import api from 'api';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ColumnService } from 'lib/services/directory';
import { AffinityXSyncStatus, getButtonTextFromSyncStatus } from './helpers';
const createBuildAdRequestEvent = ({ noticeRef, reqData }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield api.post(`integrations/async-design/notice/${noticeRef.id}/create-build-ad-request`, reqData);
        if (!resp.success) {
            throw new Error(resp.error);
        }
    }
    catch (err) {
        logAndCaptureException(ColumnService.AFFINITY_X, err, 'Problem creating BuildAdRequestEvent on client', {
            noticeId: noticeRef.id,
            orderNumber: reqData.orderNumber,
            requestingUserId: reqData.requestingUserId
        });
    }
});
function AffinityXSyncButton({ heightIsValid, noticeSnap, publicationSnap, syncStatus, noticeIsAfterDeadline, setSyncStatus, orderNumber, reqData }) {
    const [loadingSyncToAffinity, handleSyncToAffinityWithLoading] = useLoading();
    const [modalToShow, setModalToShow] = useState(null);
    const showSyncAfterDeadlineModal = modalToShow === 'after-deadline-modal';
    const showOrderAlreadyExistsModal = modalToShow === 'already-exists-modal';
    const { hasReachedFirstPublication } = noticeIsPublished(noticeSnap, publicationSnap);
    const disableSync = hasReachedFirstPublication ||
        noticeSnap.data().isArchived ||
        !reqData.numberOfColumns ||
        !reqData.approxHeightInInches ||
        !heightIsValid ||
        !orderNumber ||
        !noticeSnap.data().customId ||
        syncStatus === AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED;
    const handleSyncToAffinity = () => __awaiter(this, void 0, void 0, function* () {
        if (disableSync) {
            return;
        }
        try {
            yield createBuildAdRequestEvent({
                noticeRef: noticeSnap.ref,
                reqData: Object.assign(Object.assign({}, reqData), { orderNumber })
            });
            setSyncStatus(AffinityXSyncStatus.SYNC_IN_PROGRESS);
        }
        catch (err) {
            setSyncStatus(AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION);
        }
        setModalToShow(null);
    });
    const onSyncButtonClick = () => __awaiter(this, void 0, void 0, function* () {
        const orderAlreadyExists = syncStatus !== AffinityXSyncStatus.READY_TO_SYNC &&
            syncStatus !== AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION;
        if (orderAlreadyExists) {
            setModalToShow('already-exists-modal');
            return;
        }
        if (noticeIsAfterDeadline) {
            setModalToShow('after-deadline-modal');
            return;
        }
        yield handleSyncToAffinityWithLoading(handleSyncToAffinity, () => setSyncStatus(AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION));
    });
    const buttonText = getButtonTextFromSyncStatus(syncStatus);
    const shouldShowRunDateTooltip = hasReachedFirstPublication &&
        [
            AffinityXSyncStatus.READY_TO_SYNC,
            AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION
        ].some(status => status === syncStatus);
    return (_jsxs(_Fragment, { children: [showOrderAlreadyExistsModal && (_jsx(CancelOrSubmitModal, Object.assign({ onClose: () => setModalToShow(null), header: "Order already exists", primaryButtonText: "Yes, update order", tertiaryButtonText: "Back", onSubmit: () => {
                    if (noticeIsAfterDeadline) {
                        setModalToShow('after-deadline-modal');
                        return;
                    }
                    return handleSyncToAffinityWithLoading(handleSyncToAffinity, () => setSyncStatus(AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION));
                }, showLoadingSpinner: true }, { children: _jsxs("div", Object.assign({ className: "py-6 text-column-gray-400" }, { children: ["Order ", orderNumber, " already exists in AffinityX. Are you sure you want to update that order with new information?"] })) }))), showSyncAfterDeadlineModal && (_jsx(CancelOrSubmitModal, Object.assign({ onClose: () => setModalToShow(null), header: "Sync to AffinityX after deadline?", primaryButtonText: "Sync", tertiaryButtonText: "Back", onSubmit: () => handleSyncToAffinityWithLoading(handleSyncToAffinity, () => setSyncStatus(AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION)), showLoadingSpinner: true }, { children: _jsx("div", Object.assign({ className: "py-6 text-column-gray-400" }, { children: "The ad deadline has passed for this notice and your newspaper will be charged for the AffinityX order even if the designed ad is not complete by publication. Alternatively, you can edit the notice so that the ad deadline is in the future. Are you sure you would like to sync?" })) }))), _jsx(Tooltip, Object.assign({ classes: "w-full", helpText: noticeSnap.data().isArchived
                    ? 'You cannot sync to AffinityX because this notice has been archived. Duplicate this notice with future publication dates to place it again.'
                    : !orderNumber
                        ? 'You must confirm this notice to create a valid order number. If you have confirmed the notice and are still seeing this message, please reach out to help@column.us'
                        : shouldShowRunDateTooltip
                            ? 'You cannot sync to AffinityX because the first publication date has passed for this notice. Please edit the notice to have a future publication date.'
                            : '' }, { children: _jsx(ColumnButton, { id: "affinity-x-sync-button", fullWidth: true, primary: true, buttonText: buttonText, size: "lg", disabled: disableSync, onClick: onSyncButtonClick, loading: loadingSyncToAffinity, type: "button" }) }))] }));
}
export default AffinityXSyncButton;
