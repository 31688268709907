export default {
    apiKey: "AIzaSyCVljqD6pjc6u1AqDNBcSY3ClaZ2gNWoD8",
    authDomain: "column-staging.firebaseapp.com",
    databaseURL: "https://column-staging.firebaseio.com",
    projectId: "column-staging",
    storageBucket: "column-staging.appspot.com",
    messagingSenderId: "888642904278",
    appId: "1:888642904278:web:a411ccf73f951caf11e977",
    measurementId: "G-METB8CC63W"
};
