import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from 'lib/components/Alert';
import QuestionForm from 'routes/madlib/components/QuestionForm';
import LoadingState from 'components/LoadingState';
import FormattedEditor from './FormattedEditor';
import { useMadlibs } from '../../hooks/useMadlibs';
export default function FormattedEditorWrapper({ layout, filingType, adData, onAdChange, newspaperOrdersFormData, disableEditing, product }) {
    const onChange = (update) => {
        onAdChange(Object.assign(Object.assign({}, adData), update));
    };
    const { madlibProps, madlibPropsError, madlibPropsLoading, allQuestionsAnswered, madlibData, 
    /**
     * TODO: add some validation to ensure madlib is complete
     * before user moves on from draft content step
     */
    // madlibIsComplete,
    setAllQuestionsAnswered, setValidationResults, setRenderedHtml, setMadlibData } = useMadlibs({ filingType, adData, onChange });
    if (madlibPropsLoading) {
        return _jsx(LoadingState, {});
    }
    const { madlibConfigPath, madlibTemplate, questions } = madlibProps !== null && madlibProps !== void 0 ? madlibProps : {};
    const showMadlibEditor = !!madlibConfigPath && !!madlibTemplate && !!questions;
    if (madlibPropsError) {
        return (_jsx(Alert, { id: "madlib-error", description: "Failed to fetch template data for publishing category." }));
    }
    const showFormattedEditor = (showMadlibEditor && allQuestionsAnswered) || !showMadlibEditor;
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-center" }, { children: [showMadlibEditor && (_jsx(QuestionForm, { questions: questions, questionTemplateData: madlibData.questionTemplateData, onChange: e => {
                    // Validations for questions that are not tied to undefined as empty value
                    const allNonEmptyAnswers = e.complete &&
                        Object.entries(e.values).every(key => key[1] !== null);
                    setMadlibData(Object.assign(Object.assign({}, madlibData), { questionTemplateData: e.values }));
                    setAllQuestionsAnswered(allNonEmptyAnswers);
                } })), showFormattedEditor && (_jsx(FormattedEditor, { adData: adData, onChange: onChange, layout: layout, newspaperOrdersFormData: newspaperOrdersFormData, disableEditing: !!disableEditing, product: product, madlibProps: {
                    madlibData,
                    madlibTemplate,
                    setValidationResults,
                    setRenderedHtml,
                    setMadlibData
                } }))] })));
}
